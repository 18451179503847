import { AdsReducer } from './AdsReducer';
import { ConversionReducer } from './ConversionReducer';
import { DashboardReducer } from './DashboardReducer';
import { EventsReducer } from './EventsReducer';
import { LogsReducer } from './LogsReducer';
import { ReportsReducer } from './ReportReducer';
import { TopupReducer } from './TopupReducer';
import { TransactionsReducer } from './TransactionsReducer';
import { VendorReducer } from './VendorReducer';
import { WorldReducer } from './WorldReducer';

const { combineReducers } = require('redux');

const reducers = combineReducers({
  dashboard: DashboardReducer,
  Ads: AdsReducer,
  transactions: TransactionsReducer,
  topup: TopupReducer,
  logs: LogsReducer,
  vendors: VendorReducer,
  reports: ReportsReducer,
  events: EventsReducer,
  conversions: ConversionReducer,
  world: WorldReducer
});

export default reducers;
