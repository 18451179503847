import { GET_CITIES, GET_COUNTRIES, GET_STATES, LOAD_DONE, LOAD_REQUEST } from '../types';

const initialState = {
  countries: [],
  states: [],
  cities: [],
  isLoading: false
};

export const WorldReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_REQUEST: {
      return { ...state, isLoading: true };
    }
    case GET_COUNTRIES: {
      return { ...state, countries: payload, isLoading: false };
    }
    case GET_STATES: {
      return { ...state, states: payload, isLoading: false };
    }
    case GET_CITIES: {
      return { ...state, cities: payload, isLoading: false };
    }
    case LOAD_DONE: {
      return { ...state, isLoading: false };
    }
    default:
      return state;
  }
};
