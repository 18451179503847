import { GET_VENDOR, LOAD_DONE, LOAD_REQUEST } from '../types';

const initialState = {
  vendor: null,
  isLoading: false
};

export const VendorReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_REQUEST: {
      return { ...state, isLoading: true };
    }
    case GET_VENDOR: {
      return { ...state, vendor: payload, isLoading: false };
    }
    case LOAD_DONE: {
      return { ...state, isLoading: false };
    }

    default:
      return state;
  }
};
