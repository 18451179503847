import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import Button from '@mui/material/Button';
// import { experimentalStyled as styled } from '@mui/material/styles';
// import Dialog from '@mui/material/Dialog';
// import DialogTitle from '@mui/material/DialogTitle';
// import DialogContent from '@mui/material/DialogContent';
// import DialogActions from '@mui/material/DialogActions';
// import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';
import {
  Typography,
  CloseIcon,
  IconButton,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Button,
  Grid,
  TextField
} from '@material-ui/core';

// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
//   '& .MuiDialogContent-root': {
//     padding: theme.spacing(2)
//   },
//   '& .MuiDialogActions-root': {
//     padding: theme.spacing(1)
//   }
// }));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          {/* <CloseIcon /> */}
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
};

export default function ViewAdInfo({ open, handleClose, rowData }) {
  const [adItem, setAdItem] = useState(null);

  React.useEffect(() => {
    if (!rowData) return;
    setAdItem(rowData.ad);
  }, [rowData]);
  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Ad Info
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container spacing="4">
            <Grid item sm={12}>
              <TextField
                id="name"
                label="Name"
                variant="outlined"
                fullWidth
                margin="normal"
                value={adItem?.name || `Ad ${adItem?.id}`}
                disabled
              />
            </Grid>
            <Grid item sm={12}>
              <TextField
                id="caption"
                label="Caption"
                variant="outlined"
                fullWidth
                margin="normal"
                value={adItem?.caption}
                disabled
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                id="views"
                label="Views"
                variant="outlined"
                fullWidth
                margin="normal"
                value={adItem?.views}
                disabled
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                id="clicks"
                label="Clicks"
                variant="outlined"
                fullWidth
                margin="normal"
                value={adItem?.clicks}
                disabled
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                id="status"
                label="Clicks"
                variant="outlined"
                fullWidth
                margin="normal"
                value={adItem?.activeStatus == 1 ? 'Active' : 'Inactive'}
                disabled
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                id="actionLink"
                label="Action Link"
                variant="outlined"
                fullWidth
                margin="normal"
                value={adItem?.actionLink}
                disabled
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
