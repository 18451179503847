import { ClickAwayListener } from '@material-ui/core';
import { loginRequest, registerVendorRequest } from '../../api/AuthRequest';
import { LOAD_DONE, LOAD_REQUEST, REQUEST_SUCCESS } from '../types';

export const loginAction = (obj) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const reqObj = {
      data: {
        ...obj
      }
    };

    const { data } = await loginRequest(reqObj);
    if (data?.error?.status === 1) {
      return { error: 1, message: data?.error?.message };
    }
    // checkIfResponseOk(data);

    setAuthorization(data);
    dispatch({ type: REQUEST_SUCCESS });
    return 0;
  } catch (err) {
    return { error: 1, message: err.message };
  }
};

export const registerVendorAction = (obj) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const reqObj = {
      data: {
        ...obj
      }
    };

    const { data } = await registerVendorRequest(reqObj);
    if (data?.error?.code === 1) {
      return { error: 1, message: data?.error?.message };
    }
    // checkIfResponseOk(data);

    dispatch({ type: LOAD_DONE });
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};

const setAuthorization = (values) => {
  sessionStorage.setItem('token', values.content.token);
  sessionStorage.setItem('userProfile', JSON.stringify(values.content.data));
};

export const logoutAction = (navigate, toast) => {
  sessionStorage.clear();
  toast.info('Logout Successful');
  // navigate('/login', { replace: true });
  window.location.pathname = '/';
};

export const checkIfResponseOk = (response, dispatch) => {
  if (response?.error?.code === '1') {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: response?.error?.message };
  }
};
