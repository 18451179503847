// ----------------------------------------------------------------------

import { baseURL } from '../api';

const userProfile = JSON.parse(sessionStorage.getItem('userProfile'));

const account = {
  displayName: userProfile?.fullname,
  email: userProfile?.email,
  photoURL: `${baseURL}/static/mock-images/avatars/avatar_default.jpg`
};

export default account;
