import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Stack from '@material-ui/core/Stack';
import { useDispatch, useSelector } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import { baseURL } from '../../../api';

function ViewSummary({ open, handleClose, rowData }) {
  const dispatch = useDispatch();

  return (
    <div>
      <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Ad Summary</DialogTitle>

        <DialogContent>
            <div>
                <h5>Conversion Url: {rowData?.conversionUrl}</h5>
                
            </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ViewSummary;
