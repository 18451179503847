import axios from './index';

export const getTypeConstantsRequest = () => axios.get(`/v1/vendors/iranlowos/get/constants`);

export const getAdEventRequest = (vals) =>
  axios.get(`/v1/vendors/events/get/events/-/-/${vals?.type || '-'}/-/-`);

export const createAdEventRequest = (reqObj) =>
  axios.post('/v1/vendors/events/create/event', reqObj);

export const updateAdEventRequest = (id, reqObj) =>
  axios.put(`/v1/vendors/events/update/event/${id}`, reqObj);

export const deleteAdEventRequest = (id) => axios.delete(`/v1/vendors/events/delete/event/${id}`);
