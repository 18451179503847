import React, { useEffect, useState } from 'react';
import { filter } from 'lodash';
import {
  Card,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  LinearProgress,
  Chip,
  Box,
  Tooltip
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import refresh from '@iconify/icons-eva/refresh-fill';
import { useDispatch, useSelector } from 'react-redux';
import { Label } from '@material-ui/icons';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/_dashboard/user';
// import TOPUPLIST from '../../_mocks_/topup.json';
import NewTopup from './components/NewTopup';
import { getTopupAction } from '../../store/actions/TopupActions';
import { fDate, fDateTime, fDateTimeSuffix, formatUTCDate } from '../../utils/formatTime';
import { fCurrency } from '../../utils/formatNumber';
import { toast } from 'react-toastify';
import FilterTopup from './components/FilterTopup';
import Table from '../../components/table';

function TopupModule() {
  const dispatch = useDispatch();
  const { isLoading, TOPUPLIST } = useSelector((state) => state.topup);

  const [dataPageFetched, setDataPageFetched] = useState(1);
  const [filterLoader, setFilterLoader] = useState(false);
  const [openNewTopup, setOpenNewTopup] = useState(false);

  const toggleModal = () => {
    setOpenNewTopup(!openNewTopup);
  };

  useEffect(() => {
    dispatch(getTopupAction());
  }, []);

  const _filterTopup = async (values) => {
    setFilterLoader(true);
    const response = await dispatch(getTopupAction(values));
    if (response?.error === 1) {
      toast.info(`${response.message}`, {
        position: 'top-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        progress: undefined
      });
    }
    setFilterLoader(false);
  };

  const TABLE_HEAD = [
    { id: 'id', label: 'S/N', render: (index, row) => index + 1 },
    {
      id: 'giftName',
      label: 'Fullname',
      align: 'left',
      render: (index, rowData) => rowData.vendor.fullname
    },
    {
      id: 'requestedAmount',
      label: 'Requested Value',
      align: 'left',
      render: (index, rowData) => fCurrency(rowData.requestedAmount)
    },
    {
      id: 'amountPaid',
      label: 'Amount Paid with Card',
      align: 'left',
      render: (index, rowData) => fCurrency(rowData.amountPaid)
    },
    {
      id: 'status',
      label: 'Status',
      align: 'left',
      render: (index, rowData) => {
        const status = rowData.status;
        return (
          <Stack direction="row" alignItems="center">
            <Chip label={status} color={status === 'success' ? 'primary' : 'default'} />
            {status !== 'success' && (
              <Tooltip title="Retry">
                <Box
                  onClick={() => window.open(row.authorizationUrl, '_target')}
                  style={{ cursor: 'pointer', marginLeft: 5 }}
                >
                  <Icon icon={refresh} />
                </Box>
              </Tooltip>
            )}
          </Stack>
        );
      }
    },
    {
      id: 'channel',
      label: 'Channel',
      align: 'left',
      render: (index, rowData) => rowData.channel
    },
    {
      id: 'reference',
      label: 'Reference',
      align: 'left',
      render: (index, rowData) => rowData.reference
    },
    {
      id: 'dateCreated',
      label: 'Time Paid',
      render: (index, rowData) => fDate(rowData.dateCreated),
      align: 'left'
    }
  ];

  // const handleGetMoreData = () => {
  //   dispatch(getTopupAction({ page: dataPageFetched + 1 }));
  // };

  return (
    <Page title="Topup Module ">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Topup
          </Typography>
          <Button variant="contained" onClick={toggleModal} startIcon={<Icon icon={plusFill} />}>
            New Topup
          </Button>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <FilterTopup handleFilter={_filterTopup} loading={filterLoader} />
        </Stack>

        <Table
          tableHeader={TABLE_HEAD}
          data={TOPUPLIST}
          isLoading={isLoading}
          filterField="fullname"
          tableTitle="Topup"
          // getMoreData={handleGetMoreData}
        />

        {/* Modals */}
        <NewTopup open={openNewTopup} handleClose={toggleModal} />
      </Container>
    </Page>
  );
}

export default TopupModule;
