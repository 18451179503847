// material
import { Box, Grid, Container, Typography } from '@material-ui/core';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardAction, getWalletAction } from '../store/actions/DashboardActions';
// components
import Page from '../components/Page';
import {
  AppTasks,
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
  AppNewsUpdate,
  WalletInfo,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppCurrentSubject,
  AppConversionRates
} from '../components/_dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const dispatch = useDispatch();
  const { isLoading, wallet, dashboard } = useSelector((state) => state.dashboard);
  console.log({ wallet });
  useEffect(() => {
    dispatch(getWalletAction());
    dispatch(getDashboardAction());
  }, []);

  return (
    <Page title="Dashboard ">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Hi, Welcome back</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <WalletInfo amount={wallet && wallet[0]?.amount} title="Wallet Balance" />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppNewUsers value={dashboard?.totalAds} title="Total Ads" />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppNewUsers value={dashboard?.totalActiveAds} title="Active Ads" />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppNewUsers value={dashboard?.totalInactiveAds} title="Inactive Ads" />
          </Grid>

          {/* topup summary */}

          <Grid item xs={12} sm={6} md={3}>
            <WalletInfo amount={dashboard?.totalTopupSum} title="Total Topup" />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <WalletInfo value={dashboard?.totalSelfTopupSum} title="Online Topup" />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <WalletInfo value={dashboard?.totalSystemTopupSum} title="Admin Topup" />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <WalletInfo value={dashboard?.totalCost} title="Total View Cost" />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppNewUsers value={dashboard?.totalViews} title="Ad Views" />
          </Grid>
          {/* <Grid item xs={12} sm={6} md={3}>
          CTR
            <AppNewUsers value={dashboard?.totalViews} title="Ad Views" />
          </Grid> */}
          <Grid item xs={12} sm={6} md={3}>
            <AppNewUsers value={dashboard?.totalTopupCount} title="Topup Count" />
          </Grid>

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
}
