import React, { useEffect, useState } from 'react';
import { filter } from 'lodash';
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  LinearProgress
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Label } from '@material-ui/icons';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/_dashboard/user';
import { toast } from 'react-toastify';
import { getConversionAction } from 'src/store/actions/ConversionActions';
import FilterConversions from './components/FilterConversions';

const TABLE_HEAD = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'name', label: 'Ad Name', alignRight: false },
  { id: 'views', label: 'Ad Reach (Views)', alignRight: false },
  { id: 'clicks', label: 'Ad Clicks', alignRight: false },
  { id: 'conversion', label: 'Total Ad Conversion', alignRight: false },
  { id: 'price', label: 'Conversion Cost', alignRight: false },
  { id: 'url', label: 'URL' }
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user?.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis?.map((el) => el[0]);
}

function ConversionModule() {
  const dispatch = useDispatch();
  const { isLoading, conversions } = useSelector((state) => state.conversions);

  const [filterLoader, setFilterLoader] = useState(false);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - conversions.length) : 0;

  const filteredUsers = applySortFilter(conversions, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers?.length === 0;

  useEffect(() => {
    dispatch(getConversionAction());
  }, []);

  const _filterConversion = async (values) => {
    setFilterLoader(true);
    const response = await dispatch(getConversionAction(values));
    if (response?.error === 1) {
      //   toast.info(`${response.message}`);
    }
    setFilterLoader(false);
  };

  return (
    <Page title="Conversion Module">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Conversions
          </Typography>
        </Stack>

        <FilterConversions handleFilter={_filterConversion} loading={filterLoader} />

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {isLoading ? <LinearProgress /> : null}
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={conversions.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  //   onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          hover
                          key={row?.id}
                          tabIndex={-1}
                          role="checkbox"
                          //   selected={isItemSelected}
                          //   aria-checked={isItemSelected}
                        >
                          <TableCell>
                            <Label />
                          </TableCell>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{row?.ad?.name || `Ad ${row?.ad?.id}`}</TableCell>
                          <TableCell align="left"> {row?.ad?.views} </TableCell>

                          <TableCell align="left"> {row?.ad?.clicks} </TableCell>
                          <TableCell align="left"> {row?.ad?.conversions} </TableCell>

                          <TableCell align="left">{row?.price?.price}</TableCell>

                          <TableCell align="left">{row?.ad?.conversionUrl}</TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={conversions.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}

export default ConversionModule;
