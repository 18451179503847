import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  Paper,
  Grid,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Typography
} from '@material-ui/core';
import { DropzoneArea } from 'react-mui-dropzone';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { LoadingButton } from '@material-ui/lab';
import { createAdsAction, getPricePlanAction } from '../../../store/actions/AdActions';
import AdPricePlan from './AdPricePlan';

function NewAd({ open, handleClose }) {
  const dispatch = useDispatch();
  const { isLoading, pricePlan } = useSelector((state) => state.Ads);
  const [activePricePlans, setActivePricePlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [fileName, setFileName] = useState({ filenames: '' });
  const [fileData, setFileData] = useState(null);

  const { handleChange, handleSubmit, errors, touched, handleBlur, values, setFieldValue } =
    useFormik({
      initialValues: {
        actionLink: '',
        caption: '',
        name: '',
        maxDailyLimit: null,
        pricePlanID: '',
        adType: 'media'
      },
      validationSchema: Yup.object().shape({
        actionLink: Yup.string(),
        caption: Yup.string().required('Caption is required'),
        name: Yup.string().required('Name is required'),
        maxDailyLimit: Yup.string().nullable()
      }),
      onSubmit: async (values) => {
        if (values.pricePlanID == '') {
          toast.info('Please select a Price Plan!');
          return;
        }
        let data = {
          ...values,
          maxDailyLimit: values.maxDailyLimit == 0 || '' ? null : values.maxDailyLimit
        };
        if (values.adType == 'media') {
          data = { ...data, medias: fileData };
        }

        const response = await dispatch(createAdsAction(data));
        if (response?.error === 1) {
          toast.error(`${response.message}`);
        } else handleClose();
      }
    });

  console.log({ errors });

  const handleFile = (files) => {
    const result = [];

    [...files].map(async (item, index) => {
      const imgBase64 = await convFileToBase64(item);

      setFileName((prevState) => ({
        filenames: `${prevState.filenames}  ${item.name}`
      }));

      result.push(imgBase64);
    });

    setFileData(result);
  };

  const convFileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });

  useEffect(() => {
    dispatch(getPricePlanAction());
  }, []);

  useEffect(() => {
    if (pricePlan.length > 0) {
      const activePlans = pricePlan.filter((item) => item.accessStatus == 1);
      setActivePricePlans(activePlans);
    }
  }, [pricePlan]);

  const handlePricePlanSelect = (id) => {
    setFieldValue('pricePlanID', id);
    setSelectedPlan(id);
  };

  const renderAdType = (type) => {
    if (type == 'media') {
      return (
        <DropzoneArea
          onChange={(files) => handleFile(files)}
          showAlerts={false}
          filesLimit={5}
          maxFileSize={16000000}
        />
      );
    } else if (type === 'app') {
      return (
        <>
          <Typography variant="subtitle1">
            Submit app to our app store by contacting admin via admin@cowrywifi.com to get download
            and image link.
          </Typography>
          <TextField
            margin="normal"
            id="url"
            label="App Download Link"
            type="text"
            onChange={(e) => {
              setFieldValue('apps[0].url', e.target.value);
            }}
            value={values?.apps?.[0]?.url}
            fullWidth
          />
          <TextField
            margin="normal"
            id="icon"
            label="App Image Link"
            type="text"
            onChange={(e) => {
              setFieldValue('apps[0].icon', e.target.value);
            }}
            value={values?.apps?.[0]?.icon}
            fullWidth
          />
        </>
      );
    } else {
      return (
        <TextField
          margin="normal"
          id="link"
          label="Form Link"
          type="text"
          onChange={(e) => {
            setFieldValue('forms[0].link', e.target.value);
          }}
          value={values?.forms?.[0]?.link}
          fullWidth
        />
      );
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create Ad</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <DialogContentText>
              To create a new ad, upload the ad image, the caption and an action link to your
              product.
            </DialogContentText>

            <h4>Select Price Plan</h4>
            <Grid container spacing={2}>
              {activePricePlans.map((item, index) => (
                <Grid item xs={12} sm={6}>
                  <AdPricePlan
                    pricing={item}
                    key={index}
                    handlePricePlanSelect={handlePricePlanSelect}
                    selected={selectedPlan}
                  />
                </Grid>
              ))}

              <Grid item sm={12}>
                <TextField
                  margin="normal"
                  id="name"
                  label="Name"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  error={Boolean(errors.name && touched.name)}
                  fullWidth
                />
              </Grid>

              <Grid item sm={6}>
                <TextField
                  margin="normal"
                  id="maxDailyLimit"
                  label="Max Daily Limit"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.maxDailyLimit}
                  error={Boolean(errors.maxDailyLimit && touched.maxDailyLimit)}
                  fullWidth
                />
              </Grid>

              <Grid item sm={6}>
                <TextField
                  margin="normal"
                  id="actionLink"
                  label="Action Link"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.actionLink}
                  error={Boolean(errors.actionLink && touched.actionLink)}
                  fullWidth
                />
              </Grid>

              <Grid item sm={12}>
                <TextField
                  id="caption"
                  label="Caption"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.caption}
                  error={Boolean(errors.caption && touched.caption)}
                  helperText={touched.caption && errors.caption}
                />
              </Grid>

              <Grid item sm={12}>
                <FormControl>
                  <FormLabel id="adType">Ad Type</FormLabel>
                  <RadioGroup
                    aria-labelledby="adType"
                    name="adType"
                    value={values.adType}
                    row
                    onChange={handleChange}
                  >
                    <FormControlLabel value="media" control={<Radio />} label="Media" />
                    <FormControlLabel value="app" control={<Radio />} label="App" />
                    <FormControlLabel value="form" control={<Radio />} label="Form Link" />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item sm={12}>
                {renderAdType(values.adType)}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <LoadingButton loading={isLoading} type="submit" color="primary" variant="contained">
              Create Ad
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default NewAd;
