import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import androidFilled from '@iconify/icons-ant-design/android-filled';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingButton } from '@material-ui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { Avatar, Card, Typography, Box, TextField, Grid, Stack, Button } from '@material-ui/core';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
import { updateVendorAction } from '../../../store/actions/VendorActions';
// import { updateAdminAction } from '../../../store/actions/AdminActions';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 3),
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter,
  display: 'flex',
  alignItem: 'center',
  justifyContent: 'center'
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(20),
  height: theme.spacing(20),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.primary.dark, 0)} 0%, ${alpha(
    theme.palette.primary.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

export default function CustomCard({ handleUpdateAdminPwd }) {
  const dispatch = useDispatch();
  const [isFieldDisbaled, setIsFieldDisabled] = useState(true);
  const { isLoading, vendor } = useSelector((state) => state.vendors);

  const { handleChange, handleSubmit, errors, touched, handleBlur, values, setFieldValue } =
    useFormik({
      initialValues: {
        fullname: ``,
        username: ``,
        phone: ``,
        address: ``
      },
      validationSchema: Yup.object().shape({
        fullname: Yup.string().required('Fullname is required'),
        username: Yup.string().required('Username is required'),
        phone: Yup.string().required('Phone is required'),
        address: Yup.string().required('Address is required'),
        profilePicture: Yup.string()
      }),
      onSubmit: async (values) => {
        const response = await dispatch(updateVendorAction({ ...values }));
        if (response?.error === 1) {
          toast.error(`${response.message}`, {
            position: 'top-left',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            progress: undefined
          });
        }
      }
    });

  useEffect(() => {
    if (vendor) {
      const { fullname, username, phone, address, profilePicture } = vendor;
      setFieldValue('fullname', fullname);
      setFieldValue('username', username);
      setFieldValue('phone', phone);
      setFieldValue('address', address);
      setFieldValue('profilePicture', profilePicture);
    }
  }, [vendor]);

  const handleUpdateAdminProfile = (e) => {
    e.preventDefault();
  };

  const handleToggleFields = (e) => {
    e.preventDefault();
    setIsFieldDisabled(false);
  };
  return (
    <RootStyle>
      <Box style={{ flexGrow: 1, width: 300 }}>
        <IconWrapperStyle>
          <Avatar icon={androidFilled} width={100} height={100} />
        </IconWrapperStyle>
      </Box>

      <Box style={{ flexGrow: 2 }}>
        <form>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                autoFocus
                margin="normal"
                id="fullname"
                label="Fullname"
                type="text"
                disabled={isFieldDisbaled}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.fullname}
                error={Boolean(errors.fullname && touched.fullname)}
                fullWidth
              />

              <TextField
                autoFocus
                margin="normal"
                id="username"
                label="Username"
                type="text"
                disabled={isFieldDisbaled}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.username}
                error={Boolean(errors.username && touched.username)}
                fullWidth
              />
              {/* <TextField
                autoFocus
                margin="normal"
                id="email"
                label="Email"
                type="email"
                disabled={isFieldDisbaled}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                error={Boolean(errors.email && touched.email)}
                fullWidth
              /> */}
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                autoFocus
                margin="normal"
                id="phone"
                label="Phone Number"
                type="number"
                disabled={isFieldDisbaled}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
                error={Boolean(errors.phone && touched.phone)}
                fullWidth
              />

              <TextField
                autoFocus
                margin="normal"
                id="address"
                label="Address"
                type="text"
                disabled={isFieldDisbaled}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.address}
                error={Boolean(errors.address && touched.address)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Stack direction="row" spacing={3}>
                <LoadingButton
                  // fullWidth
                  loading={isLoading}
                  color="primary"
                  variant="contained"
                  onClick={isFieldDisbaled ? (e) => handleToggleFields(e) : (e) => handleSubmit()}
                >
                  {' '}
                  {isFieldDisbaled ? 'Edit Profile' : 'Submit'}
                </LoadingButton>
                <Button color="primary" variant="outlined" onClick={handleUpdateAdminPwd}>
                  Change Password
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </Box>
    </RootStyle>
  );
}
