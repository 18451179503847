import React, { useEffect, useState } from 'react';
import { Card, CardContent, Grid } from '@material-ui/core';
import NVD3Chart from 'react-nvd3';
import { useDispatch, useSelector } from 'react-redux';
import { getReportsAction } from 'src/store/actions/ReportActions';
import { AppNewUsers } from 'src/components/_dashboard/app';
import ReportCard from 'src/components/_dashboard/app/ReportCard';
import FilterReports from './components/FilterReports';

const ReportsModule = () => {
  const [filterLoader, setFilterLoader] = useState(false);
  const dispatch = useDispatch();
  const { REPORTS } = useSelector((state) => state.reports);

  useEffect(() => {
    dispatch(getReportsAction());
  }, []);

  const adsData = [
    {
      key: 'Ads Data',
      values: [
        {
          label: 'All',
          value: REPORTS?.totalAds,
          color: '#04a9f5'
        },
        {
          label: 'Active',
          value: REPORTS?.totalActiveAds,
          color: '#ff8a65'
        },
        {
          label: 'Inactive',
          value: REPORTS?.totalInactiveAds,
          color: '#ff8a65'
        },
        {
          label: 'Assigned',
          value: REPORTS?.totalAssignedAds,
          color: '#1de9b6'
        },
        {
          label: 'Blocked',
          value: REPORTS?.totalBlockedAds,
          color: '#4C5667'
        }
      ]
    }
  ];

  const _handleFilterReports = async (values) => {
    setFilterLoader(true);
    const response = await dispatch(getReportsAction(values));
    if (response?.error === 1) {
      toast.info(`${response.message}`);
    }
    setFilterLoader(false);
  };

  return (
    <>
      <FilterReports handleFilter={_handleFilterReports} loading={filterLoader} />
      <Grid container style={{ marginBottom: 10 }} spacing={4}>
        <Grid item md={6}>
          <AppNewUsers value={REPORTS?.totalViews} title="Total Views" />
        </Grid>
        <Grid item md={3}>
          <AppNewUsers value={REPORTS?.totalActiveAds} title="Active Ads" />
        </Grid>
        <Grid item md={3}>
          <AppNewUsers value={REPORTS?.totalRoutes} title="Total Routes" />
        </Grid>
      </Grid>
      <Grid spacing={4} container style={{ marginBottom: 10 }}>
        <Grid item md={4}>
          <ReportCard
            main_title="Topup"
            value={REPORTS?.totalSelfTopupSum || 0}
            sub_title="Count"
            count={REPORTS?.totalSelfTopupCount}
          />
        </Grid>
        <Grid item md={4}>
          <ReportCard
            main_title="System Topup"
            value={REPORTS?.totalSystemTopupSum || 0}
            sub_title="System Topup Count"
            count={REPORTS?.totalSystemTopupCount}
          />
        </Grid>
        <Grid item md={4}>
          <ReportCard
            main_title="Total Topup"
            value={REPORTS?.totalTopupSum || 0}
            sub_title="Topup Count"
            count={REPORTS?.totalTopupCount}
          />
        </Grid>
      </Grid>
      <Grid container spacing={4} style={{ marginBottom: 10, marginTop: 20 }}>
        <Grid item md={6}>
          <h3>Ads Report</h3>

          <Card>
            <CardContent style={{ padding: '20px', borderWidth: '2px' }}>
              <NVD3Chart
                tooltip={{ enabled: true }}
                type="discreteBarChart"
                datum={adsData}
                x="label"
                y="value"
                height={300}
                showValues
                staggerLabels
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default ReportsModule;
