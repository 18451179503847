import { GET_DASHBOARD, GET_WALLET, LOAD_REQUEST } from '../types';

const initialState = {
  dashboard: null,
  wallet: null,
  isLoading: false
};

export const DashboardReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_REQUEST: {
      return { ...state, isLoading: true };
    }
    case GET_DASHBOARD: {
      return { ...state, dashboard: payload, isLoading: false };
    }
    case GET_WALLET: {
      return { ...state, wallet: payload, isLoading: false };
    }
    default:
      return state;
  }
};
