import React, { useEffect, useState } from 'react';
import { filter } from 'lodash';
import {
  Card,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  LinearProgress,
  Chip
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Label, Done, Delete, Close } from '@material-ui/icons';
import { toast } from 'react-toastify';
import { fDateTime } from '../../utils/formatTime';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/_dashboard/user';
// import ADSLIST from '../../_mocks_/ads.json';
import NewAd from './components/NewAd';
import EditAd from './components/EditAd';
import { deleteAdAction, getAdAction, toggleAdStatusAction } from '../../store/actions/AdActions';
import AssignAd from './components/AssignAd';
import ViewRoutes from './components/ViewRoutes';
import ViewMedia from './components/ViewMedia';
import AdEvents from './components/AdEvents';
import ViewSummary from './components/ViewSummary';
import AssignPlaces from './components/AssignPlaces';
import FilterAds from './components/FilterAds';
import Table from '../../components/table';
import { fDate } from '../../utils/formatTime';

function AdsModule() {
  const dispatch = useDispatch();
  const { isLoading, ADSLIST } = useSelector((state) => state.Ads);

  const [openNewAd, setOpenNewAd] = useState(false);
  const [openEditAd, setOpenEditAd] = useState(false);
  const [openAssignAd, setOpenAssignAd] = useState(false);
  const [openAssignPlaces, setOpenAssignPlaces] = useState(false);
  const [openViewRoutes, setOpenViewRoutes] = useState(false);
  const [showMedia, setShowMedia] = useState(false);
  const [openAdEvents, setOpenAdEvents] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [openAdSummary, setOpenAdSummary] = useState(false);
  const [filterLoader, setFilterLoader] = useState(false);
  const [dataPageFetched, setDataPageFetched] = useState(1);

  const toggleModal = () => {
    setOpenNewAd(!openNewAd);
  };

  const handleEditAd = (row) => {
    setOpenEditAd(!openEditAd);
    setCurrentRow(row);
  };

  const handleCloseEditModal = () => {
    setOpenEditAd(false);
  };

  const handleAssignAd = (row) => {
    setOpenAssignAd(!openAssignAd);
    setCurrentRow(row);
  };

  const handleAssignPlaces = (row) => {
    setOpenAssignPlaces(!openAssignPlaces);
    setCurrentRow(row);
  };

  const handleCloseAssignModal = () => {
    setOpenAssignAd(false);
    setOpenAssignPlaces(false);
  };

  const handleViewRoutes = (row) => {
    setOpenViewRoutes(!openViewRoutes);
    setCurrentRow(row);
  };

  const handleCloseViewRoutesModal = () => {
    setOpenViewRoutes(false);
  };

  const handleShowMedia = (row) => {
    setShowMedia(!showMedia);
    setCurrentRow(row);
  };

  const handleCloseShowMedia = () => {
    setShowMedia(false);
  };

  const handleAdEvents = (row) => {
    setOpenAdEvents(!openAdEvents);
    setCurrentRow(row);
  };

  const handleAdSummary = (row) => {
    setOpenAdSummary(!openAdSummary);
    setCurrentRow(row);
  };

  const handleCloseAdEvents = () => {
    setOpenAdEvents(false);
    setOpenAdSummary(false);
  };

  useEffect(() => {
    dispatch(getAdAction({ page: dataPageFetched }));
  }, []);

  const handleToggleStatus = async (id, status) => {
    const action = window.confirm(
      `Are you sure you want to ${status === 1 ? 'activate' : 'deactivate'} this Ad`
    );
    if (action) {
      const response = await dispatch(toggleAdStatusAction(id, status));
      if (response?.error === 1) {
        toast.error(`${response.message}`);
      }
    }
  };

  const handleDeleteAd = async (id) => {
    const action = window.confirm(`Are you sure you want to delete this Ad`);
    if (action) {
      const response = await dispatch(deleteAdAction(id));
      if (response?.error === 1) {
        toast.error(`${response.message}`);
      }
    }
  };

  const _filterAds = async (values) => {
    setFilterLoader(true);
    const response = await dispatch(getAdAction(values));
    if (response?.error === 1) {
      toast.info(`${response.message}`);
    }
    setFilterLoader(false);
  };

  const TABLE_HEAD = [
    { id: 'id', label: 'S/N', render: (index, row) => index + 1 },
    {
      id: 'name',
      label: 'Name',
      align: 'left',
      render: (index, rowData) => rowData?.name || `Ad ${rowData?.id}`
    },
    {
      id: 'actionLink',
      label: 'Action Link',
      align: 'left',
      render: (index, rowData) => rowData.actionLink.substring(0, 20) + '...'
    },
    {
      id: 'caption',
      label: 'Caption',
      align: 'left',
      render: (index, rowData) => rowData.caption.substring(0, 14) + '...'
    },
    {
      id: 'views',
      label: 'Views',
      align: 'left',
      render: (index, rowData) => rowData.views
    },
    {
      id: 'adType',
      label: 'Ad Type',
      align: 'left',
      render: (index, rowData) => rowData.adType
    },
    {
      id: 'conversionUrl',
      label: 'Conversion Url',
      align: 'left',
      render: (index, rowData) => (
        <>
          <kbd>curl --location --request PUT </kbd> <code>{rowData.conversionUrl}</code>
        </>
      )
    },
    {
      id: 'activeStatus',
      label: 'Status',
      align: 'left',
      render: (index, rowData) => (
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
          <Chip
            label={rowData.activeStatus == 1 ? 'Active' : 'Inactive'}
            color={rowData.activeStatus == 1 ? 'primary' : 'default'}
            clickable
          />
          {rowData.activeStatus == 1 ? (
            <Close
              style={{ cursor: 'pointer' }}
              onClick={() => handleToggleStatus(rowData.id, 0)}
            />
          ) : (
            <Done style={{ cursor: 'pointer' }} onClick={() => handleToggleStatus(rowData.id, 1)} />
          )}
        </Stack>
      )
    },
    {
      id: 'dateCreated',
      label: 'Time Paid',
      render: (index, rowData) => fDateTime(rowData.dateCreated),
      align: 'left'
    },
    {
      id: 'action',
      label: 'Summary',
      render: (index, rowData) => (
        <Button variant="outlined" onClick={() => handleShowMedia(rowData)}>
          View Summary
        </Button>
      ),
      align: 'left'
    },
    {
      id: 'action',
      label: 'Actions',
      render: (index, rowData) => (
        <UserMoreMenu
          handleEdit={() => handleEditAd(rowData)}
          handleDelete={() => handleDeleteAd(rowData.id)}
          handleAssignAd={() => handleAssignAd(rowData)}
          handleAssignPlaces={() => handleAssignPlaces(rowData)}
          handleViewRoute={() => handleViewRoutes(rowData)}
          handleAdEvents={() => handleAdEvents(rowData)}
        />
      ),
      align: 'left'
    }
  ];

  const handleGetMoreData = () => {
    dispatch(getAdAction({ page: dataPageFetched + 1 }));
    setDataPageFetched((prev) => prev + 1);
  };

  return (
    <Page title="Ads Module ">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Ads
          </Typography>
          <Button
            variant="contained"
            onClick={toggleModal}
            open={openNewAd}
            handleClose={toggleModal}
            startIcon={<Icon icon={plusFill} />}
          >
            New Ads
          </Button>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <FilterAds handleFilter={_filterAds} loading={filterLoader} />
        </Stack>

        <Table
          tableHeader={TABLE_HEAD}
          data={ADSLIST}
          isLoading={isLoading}
          filterField="fullname"
          tableTitle="Ads"
          getMoreData={handleGetMoreData}
        />

        {/* Modals */}
        <NewAd open={openNewAd} handleClose={toggleModal} />
        <EditAd open={openEditAd} handleClose={handleCloseEditModal} rowData={currentRow} />
        <AssignAd open={openAssignAd} handleClose={handleCloseAssignModal} rowData={currentRow} />
        <AssignPlaces
          open={openAssignPlaces}
          handleClose={handleCloseAssignModal}
          rowData={currentRow}
        />
        <ViewRoutes
          open={openViewRoutes}
          handleClose={handleCloseViewRoutesModal}
          rowData={currentRow}
        />
        <ViewMedia open={showMedia} handleClose={handleCloseShowMedia} rowData={currentRow} />
        <AdEvents open={openAdEvents} handleClose={handleCloseAdEvents} rowData={currentRow} />
        <ViewSummary open={openAdSummary} handleClose={handleCloseAdEvents} rowData={currentRow} />
      </Container>
    </Page>
  );
}

export default AdsModule;
