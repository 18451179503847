import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  FormControl,
  FormGroup,
  FormLabel,
  FormControlLabel,
  Checkbox,
  FormHelperText
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { LoadingButton } from '@material-ui/lab';
import {
  assignRoutesAction,
  getRoutesAction,
  updateAdsAction
} from '../../../store/actions/AdActions';

function AssignAd({ open, handleClose, rowData }) {
  const dispatch = useDispatch();
  const { ROUTES } = useSelector((state) => state.Ads);
  const { isLoading } = useSelector((state) => state.Ads);
  const [currentItem, setCurrentItem] = useState(null);

  useEffect(() => {
    setCurrentItem(rowData);
  }, [rowData]);

  useEffect(() => {
    dispatch(getRoutesAction());
  }, []);

  const { handleSubmit, errors, touched, handleBlur, values, setFieldValue } = useFormik({
    initialValues: {
      adID: '',
      routeIDs: [],
      all: false
    },
    validationSchema: Yup.object().shape({
      routeIDs: Yup.array().min(1, 'Assign at least one route!')
    }),
    onSubmit: async (values) => {
      const parsedRouteIDs = parseRouteID(values.routeIDs);
      const response = await dispatch(
        assignRoutesAction({ routeIDs: parsedRouteIDs, adID: rowData.id.toString() })
      );
      if (response?.error === 1) {
        toast.error(`${response.message}`, {
          position: 'top-left',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          progress: undefined
        });
      } else handleClose();
    }
  });

  const parseRouteID = (arr) => arr.map((i) => Number(i));

  const handleChange = (e) => {
    const { checked, name } = e.target;
    if (checked) {
      setFieldValue('routeIDs', [...values.routeIDs, name]);
    } else {
      setFieldValue(
        'routeIDs',
        values.routeIDs.filter((v) => v !== name)
      );
    }
  };

  const handleSelectAll = (e) => {
    const { checked, name } = e.target;
    if (checked) {
      const allIDs = Boolean(ROUTES) && ROUTES.map((route) => route.id.toString());
      setFieldValue('routeIDs', allIDs);
      setFieldValue('all', true);
    } else {
      setFieldValue('routeIDs', []);
      setFieldValue('all', false);
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Assign Routes </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <FormControl component="fieldset">
              <FormLabel component="legend">Assign your preferred routes to show this Ad</FormLabel>
              <FormGroup>
                <FormControlLabel
                  key={'all'}
                  control={
                    <Checkbox
                      checked={values.all}
                      onChange={handleSelectAll}
                      name={'all'}
                      id={'all'}
                    />
                  }
                  label={'Assign to all Routes'}
                />
              </FormGroup>
            </FormControl>

            <FormControl component="fieldset">
              {/* <FormLabel component="legend">Assign your preferred routes to show this Ad</FormLabel> */}
              <FormGroup>
                {ROUTES.length > 0 &&
                  ROUTES.map((route) => (
                    <FormControlLabel
                      key={route.id}
                      control={
                        <Checkbox
                          checked={values.routeIDs.includes(route.id.toString())}
                          onChange={handleChange}
                          name={route.id.toString()}
                          id={route.id.toString()}
                        />
                      }
                      label={route.name}
                    />
                  ))}
                {!Boolean(ROUTES) && 'Loading...'}
              </FormGroup>
              <FormHelperText>{touched.routeIDs && errors.routeIDs}</FormHelperText>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <LoadingButton loading={isLoading} type="submit" color="primary" variant="contained">
              Assign Route
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default AssignAd;
