import { toast } from 'react-toastify';
import {
  getVendorRequest,
  updateVendorPasswordRequest,
  updateVendorRequest
} from '../../api/VendorRequest';
import { GET_VENDOR, LOAD_DONE, LOAD_REQUEST } from '../types';
import { checkIfResponseOk } from './AuthActions';

export const getVendorAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await getVendorRequest();
    //   if (data?.error?.status === 1) {
    //     return { error: 1, message: data?.error?.message };
    //   }
    checkIfResponseOk(data, dispatch);

    dispatch({ type: GET_VENDOR, payload: data.content.data });
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};

export const updateVendorAction = (obj) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });
    const reqObj = {
      data: {
        ...obj
      }
    };
    const { data } = await updateVendorRequest(reqObj);
    if (data?.error?.code === 1) {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }

    toast.success('Updated Sucessfully!', {
      position: 'top-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });

    dispatch(getVendorAction());
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};

export const updateVendorPasswordAction = (obj) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });
    const reqObj = {
      data: {
        ...obj
      }
    };
    const { data } = await updateVendorPasswordRequest(reqObj);
    if (data?.error?.code === 1) {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }

    toast.success('Password Updated Sucessfully!', {
      position: 'top-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });

    dispatch(getVendorAction());
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};
