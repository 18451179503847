import { removeDupsFromArray } from 'src/utils/utilities';
import { GET_ADS, GET_PRICE_PLAN, GET_ROUTES, LOAD_DONE, LOAD_REQUEST } from '../types';

const initialState = {
  ADSLIST: [],
  ROUTES: [],
  isLoading: false,
  pricePlan: []
};

export const AdsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_REQUEST: {
      return { ...state, isLoading: true };
    }
    case GET_ADS: {
      return {
        ...state,
        ADSLIST: payload,
        isLoading: false
      };
    }
    case LOAD_DONE: {
      return { ...state, isLoading: false };
    }
    case GET_ROUTES: {
      return { ...state, ROUTES: payload, isLoading: false };
    }
    case GET_PRICE_PLAN: {
      return { ...state, pricePlan: payload, isLoading: false };
    }
    default:
      return state;
  }
};
