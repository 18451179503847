import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { LoadingButton } from '@material-ui/lab';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, IconButton, InputAdornment, Autocomplete, MenuItem } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import {
  getCitiesAction,
  getCountriesAction,
  getStatesAction
} from 'src/store/actions/WorldActions';
import { getRoutesAction } from 'src/store/actions/AdActions';

function FilterAds({ handleFilter, loading }) {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [fromDate, setFromDate] = useState(moment().subtract(1, 'd').format('YYYY-MM-DD')); //gets the last sunday as default fromDate value
  const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));

  const [options, setOptions] = useState({ country: [], state: [], city: [] });
  const { countries, states, cities } = useSelector((state) => state.world);
  const { ROUTES } = useSelector((state) => state.Ads);

  const { handleSubmit, handleChange, values, errors, touched, handleBlur, setFieldValue } =
    useFormik({
      initialValues: {
        routeID: '',
        countryID: '',
        countryLabel: '',
        stateID: '',
        stateLabel: '',
        cityID: '',
        cityLabel: '',
        activeStatus: '',
        dateFrom: '',
        dateTo: '',
        adType: ''
      },
      validationSchema: Yup.object().shape({
        //   vendorID: Yup.string().required('Vendor is required')
      }),
      onSubmit: (values) => {
        handleFilter({ ...values });
      }
    });

  useEffect(() => {
    dispatch(getCountriesAction());
    dispatch(getRoutesAction());
  }, []);

  useEffect(() => {
    if (countries.length > 0) {
      const countryOptions = countries.map((item) => ({ label: item.name, id: item.id }));
      const stateOptions = states.map((item) => ({ label: item.name, id: item.id }));
      const cityOptions = cities.map((item) => ({ label: item.name, id: item.id }));

      countryOptions.sort((a, b) => a.label.localeCompare(b.label));
      stateOptions.sort((a, b) => a.label.localeCompare(b.label));
      cityOptions.sort((a, b) => a.label.localeCompare(b.label));
      setOptions((prev) => ({
        country: [...countryOptions],
        state: [...stateOptions],
        city: [...cityOptions]
      }));
    }
  }, [countries, states, cities]);

  return (
    <div style={{ width: '100%' }}>
      <form onSubmit={handleSubmit}>
        {/* <Stack direction="row" alignItems="center" spacing={2} flexWrap="wrap" justifyContent="space-between"> */}
        <Grid container spacing={2}>
          <Grid item sm={12} md={3}>
            <TextField
              error={Boolean(touched.routeID && errors.routeID)}
              helperText={touched.routeID && errors.routeID}
              fullWidth
              select
              margin="normal"
              name="routeID"
              label="Route"
              variant="outlined"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.routeID}
            >
              <MenuItem value="">Please select Route</MenuItem>
              {ROUTES.map((item, index) => (
                <MenuItem key={index} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item sm={12} md={3}>
            <Autocomplete
              disablePortal
              id="countryID"
              options={options.country}
              name="countryID"
              onBlur={handleBlur}
              onChange={(event, newValue) => {
                setFieldValue('countryID', newValue?.id || null);
                setFieldValue('countryLabel', newValue?.label);
                if (newValue?.id !== undefined) {
                  dispatch(getStatesAction({ countryID: newValue.id }));
                }
              }}
              fullWidth
              value={values.countryLabel}
              renderInput={(params) => <TextField {...params} margin="normal" label="Country" />}
            />
          </Grid>

          <Grid item sm={12} md={3}>
            <Autocomplete
              disablePortal
              id="stateID"
              options={options.state}
              name="stateID"
              onBlur={handleBlur}
              onChange={(event, newValue) => {
                setFieldValue('stateID', newValue?.id || null);
                setFieldValue('stateLabel', newValue?.label);
                if (newValue?.id !== undefined) {
                  dispatch(getCitiesAction({ stateID: newValue.id }));
                }
              }}
              fullWidth
              value={values.stateLabel}
              renderInput={(params) => <TextField {...params} margin="normal" label="State" />}
            />
          </Grid>

          <Grid item sm={12} md={3}>
            <Autocomplete
              disablePortal
              id="cityID"
              options={options.city}
              // sx={{ width: 300 }}
              name="cityID"
              onBlur={handleBlur}
              onChange={(event, newValue) => {
                setFieldValue('cityID', newValue?.id || null);
                setFieldValue('cityLabel', newValue?.label);
              }}
              fullWidth
              value={values.cityLabel}
              renderInput={(params) => <TextField {...params} margin="normal" label="City" />}
            />
          </Grid>

          <Grid item md={3}>
            <TextField
              autoFocus
              margin="normal"
              id="dateFrom"
              label="From Date"
              type="date"
              fullWidth
              defaultValue={fromDate}
              onChange={(e) => {
                setFieldValue('dateFrom', moment(e.target.value).unix());
                setFromDate(e.target.value);
              }}
              onBlur={handleBlur}
              error={Boolean(touched.dateFrom && errors.dateFrom)}
              helperText={touched.dateFrom && errors.dateFrom}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>

          <Grid item md={3}>
            <TextField
              autoFocus
              margin="normal"
              id="dateTo"
              label="To Date"
              type="date"
              fullWidth
              defaultValue={toDate}
              //   value={toDate}
              onChange={(e) => {
                setFieldValue('dateTo', moment(e.target.value).unix());
                setToDate(e.target.value);
              }}
              onBlur={handleBlur}
              error={Boolean(touched.dateTo && errors.dateTo)}
              helperText={touched.dateTo && errors.dateTo}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item md={3}>
            <TextField
              error={Boolean(touched.activeStatus && errors.activeStatus)}
              helperText={touched.activeStatus && errors.activeStatus}
              fullWidth
              select
              margin="normal"
              name="activeStatus"
              label="Status"
              variant="outlined"
              onBlur={handleBlur}
              size="small"
              onChange={handleChange}
              value={values.activeStatus}
            >
              <MenuItem value="">Please select </MenuItem>
              <MenuItem value="1">Active</MenuItem>
              <MenuItem value="0">Inactive</MenuItem>
            </TextField>
          </Grid>
          <Grid item md={3}>
            <TextField
              error={Boolean(touched.adType && errors.adType)}
              helperText={touched.adType && errors.adType}
              fullWidth
              select
              margin="normal"
              name="adType"
              label="Ad Type"
              variant="outlined"
              onBlur={handleBlur}
              size="small"
              onChange={handleChange}
              value={values.adType}
            >
              <MenuItem value="">Please select </MenuItem>
              <MenuItem value="media">Media</MenuItem>
              <MenuItem value="app">App</MenuItem>
              <MenuItem value="form">Form</MenuItem>
            </TextField>
          </Grid>

          <Grid sm={3} alignContent="flex-end" justifyContent="flex-end">
            <LoadingButton
              style={{ alignSelf: 'flex-end', marginLeft: '15px' }}
              type="submit"
              color="primary"
              variant="contained"
              loading={loading}
            >
              Filter
            </LoadingButton>
          </Grid>
        </Grid>
        {/* </Stack> */}
      </form>
    </div>
  );
}

export default FilterAds;
