import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Stack from '@material-ui/core/Stack';
import { useDispatch, useSelector } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import { baseURL } from '../../../api';
import { fCurrency } from 'src/utils/formatNumber';
import { Grid } from '@material-ui/core';

function ViewMedia({ open, handleClose, rowData }) {
  return (
    <div>
      <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Ad Summary</DialogTitle>

        <DialogContent>
          <div style={{ width: 200 }}>
            <table className="ad-table">
              <tr>
                <th>Action</th>
                <th>Summary</th>
              </tr>
              <tr>
                <td>States Ad is shown in</td>
                <td>
                  {rowData?.states.map((item, index) => (
                    <p key={index}>{item.name}</p>
                  ))}{' '}
                </td>
              </tr>

              <tr>
                <td>Reach (Views)</td>
                <td>{rowData?.views} </td>
              </tr>
              <tr>
                <td>Clicks</td>
                <td>{rowData?.clicks}</td>
              </tr>
              <tr>
                <td>Current Daily Cost</td>
                <td>{rowData?.currDailyCost}</td>
              </tr>
              <tr>
                <td>Conversions</td>
                <td>{rowData?.conversions}</td>
              </tr>
              <tr>
                <td>Max Daily Limit</td>
                <td>{fCurrency(rowData?.maxDailyLimit)}</td>
              </tr>
              <tr>
                <td>Conversion Url</td>
                <td>{rowData?.conversionUrl}</td>
              </tr>
              {/* <tr>
                <th>Reach (Views)</th>
                <th>Clicks</th>
                <th>Current Daily Cost</th>
                <th>Max Daily Limit</th>
                <th>Conversion Url</th>
              </tr>
              <tr>
                <td>{rowData?.views}</td>
                <td> {rowData?.clicks} </td>
                <td> {rowData?.currDailyCost} </td>
                <td> {fCurrency(rowData?.maxDailyLimit)}</td>
                <td> {rowData?.conversionUrl}</td>
              </tr> */}
            </table>
          </div>

          {rowData?.ad_medias.length > 0 ? (
            <Carousel centerMode>
              {rowData.ad_medias.map((item, index) => {
                const isHttpUrl = item.media.substring(0, 4).toLowerCase();
                if (item.mediaType === 'IMAGE') {
                  return (
                    <img
                      src={isHttpUrl === 'http' ? `${item.media}` : `${baseURL}/${item.media}`}
                      key={index}
                      alt={item.media}
                      // alt={`${imgBase}/${item.timeline_media}`}
                    />
                  );
                }
                return (
                  <video
                    src={isHttpUrl === 'http' ? `${item.media}` : `${baseURL}/${item.media}`}
                    autoPlay
                    controls
                    key={index}
                    style={{ maxWidth: '500px' }}
                  >
                    <track default kind="captions" srcLang="en" />
                    <source src={`${item.media}`} type="video/mp4" />
                    <source src={`${item.media}`} type="video/ogg" />
                    <source src={`${item.media}`} type="video/avi" />
                  </video>
                );
              })}
            </Carousel>
          ) : (
            <h4 style={{ textAlign: 'center' }}>No media available...</h4>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ViewMedia;
