import { EMPTY_LIST, GET_REPORTS, LOAD_DONE, LOAD_REQUEST } from '../types';

const initialState = {
  REPORTS: [],
  isLoading: false
};

export const ReportsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_REQUEST: {
      return { ...state, isLoading: true };
    }
    case GET_REPORTS: {
      return { ...state, REPORTS: payload, isLoading: false };
    }

    case EMPTY_LIST: {
      return { ...state, isLoading: false, REPORTS: [] };
    }

    case LOAD_DONE: {
      return { ...state, isLoading: false };
    }
    default:
      return state;
  }
};
