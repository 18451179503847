import { getDayBefore, getTodayDate } from 'src/utils/formatNumber';
import axios from './index';

export const getTopupRequest = (values) =>
  axios.get(
    `/v1/vendors/get/topups/-/-/${values?.reference || '-'}/${values?.status || '-'}/${
      values?.from || getDayBefore()
    }/${values?.to || getTodayDate()}/-/-`
  );

export const createTopupRequest = (reqObj) => axios.post('/v1/vendors/create/topup', reqObj);
