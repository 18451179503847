import { toast } from 'react-toastify';
import { createTopupRequest, getTopupRequest } from '../../api/TopupRequest';
import { GET_TOPUP, LOAD_DONE, LOAD_REQUEST, EMPTY_LIST } from '../types';
import { checkIfResponseOk } from './AuthActions';

export const getTopupAction = (values) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await getTopupRequest(values);
    //   if (data?.error?.status === 1) {
    //     return { error: 1, message: data?.error?.message };
    //   }
    // checkIfResponseOk(data);
    if (data?.error?.code === '1' && data?.error?.message === 'No more data') {
      dispatch({ type: EMPTY_LIST });
      return { error: 1, message: data?.error?.message };
    } else if (data?.error?.code === '1') {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }

    dispatch({ type: GET_TOPUP, payload: data.content.data.all });
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};

export const createTopupAction = (obj) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });
    const reqObj = {
      data: {
        ...obj
      }
    };
    const { data } = await createTopupRequest(reqObj);
    if (data?.error?.code === 1) {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }

    toast.success('Created Sucessfully!', {
      position: 'top-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
    dispatch(getTopupAction());
    return { response: data.content.data };
  } catch (err) {
    return { error: 1, message: err.message };
  }
};
