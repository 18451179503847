import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';
// import googleAds from '@iconify-icons/mdi/google-ads';
// import { Icon, InlineIcon } from '@iconify/react';
import monitorFill from '@iconify-icons/eva/monitor-fill';
import briefcaseFill from '@iconify-icons/eva/briefcase-fill';
import creditCardFill from '@iconify-icons/eva/credit-card-fill';
import layersFill from '@iconify-icons/eva/layers-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: 'ads',
    path: '/dashboard/ads',
    icon: getIcon(monitorFill)
  },
  // {
  //   title: 'conversions',
  //   path: '/dashboard/conversions',
  //   icon: getIcon(monitorFill)
  // },
  {
    title: 'conversion-events',
    path: '/dashboard/conversion-events',
    icon: getIcon(monitorFill)
  },
  {
    title: 'topup',
    path: '/dashboard/topup',
    icon: getIcon(briefcaseFill)
  },
  // {
  //   title: 'summary',
  //   path: '/dashboard/transactions',
  //   icon: getIcon(creditCardFill)
  // },
  {
    title: 'reports',
    path: '/dashboard/reports',
    icon: getIcon(creditCardFill)
  },
  {
    title: 'activity log',
    path: '/dashboard/activitylog',
    icon: getIcon(layersFill)
  },
  {
    title: 'vendor profile',
    path: '/dashboard/vendorprofile',
    icon: getIcon(peopleFill)
  }
];

export default sidebarConfig;
