import {
  createAdEventRequest,
  deleteAdEventRequest,
  getAdEventRequest,
  getTypeConstantsRequest,
  updateAdEventRequest
} from 'src/api/EventsRequest';
import { GET_AD_EVENTS, GET_CONSTANTS, LOAD_DONE, LOAD_REQUEST } from '../types';
import { toast } from 'react-toastify';
import { getAdAction } from './AdActions';

export const getConstantsAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await getTypeConstantsRequest();
    if (data?.error?.code === '1') {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }

    dispatch({ type: GET_CONSTANTS, payload: data.content.data.eventTypes });
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};

export const createAdEventsAction = (obj) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });
    const reqObj = {
      data: {
        ...obj
      }
    };

    const { data } = await createAdEventRequest(reqObj);
    if (data?.error?.status === 1) {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }

    toast.success('Created Sucessfully!');

    dispatch(getAdEventsAction());
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};

export const getAdEventsAction = (vals) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await getAdEventRequest(vals);

    if (data?.error?.code === '1') {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }

    dispatch({ type: GET_AD_EVENTS, payload: data.content.data.all });
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};

export const updateAdEventsAction = (obj) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });
    const reqObj = {
      data: {
        ...obj
      }
    };

    const { data } = await updateAdEventRequest(obj.adID, reqObj);
    if (data?.error?.status === 1) {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }

    toast.success('Updated Sucessfully!');

    dispatch(getAdEventsAction());
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};

export const deleteAdEventsAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await deleteAdEventRequest(id);
    if (data?.error?.status === 1) {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }

    toast.success('Deleted Sucessfully!');

    dispatch(getAdEventsAction());
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};
