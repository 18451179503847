import { toast } from 'react-toastify';
import {
  assignAdToPlacesRequest,
  assignRouteRequest,
  createAdsRequest,
  deleteAdRequest,
  getAdsRequest,
  getPricePlanRequest,
  getRoutesRequest,
  toggleStatusRequest,
  unassignRouteRequest,
  updateAdRequest
} from '../../api/AdRequest';
import { GET_ADS, GET_PRICE_PLAN, GET_ROUTES, LOAD_DONE, LOAD_REQUEST } from '../types';

export const getAdAction = (values) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await getAdsRequest(values);
    if (data?.error?.code === '1') {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }

    dispatch({ type: GET_ADS, payload: data.content.data.all });
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};

export const checkIfResponseOk = (response) => {
  if (response?.error?.status === 1) {
    return { error: 1, message: response?.error?.message };
  }
};

export const createAdsAction = (obj) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });
    const reqObj = {
      data: {
        ...obj
      }
    };

    const { data } = await createAdsRequest(reqObj);
    if (data?.error?.status === 1) {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }

    toast.success('Created Sucessfully!', {
      position: 'top-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });

    dispatch(getAdAction());
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};

export const updateAdsAction =
  ({ id, ...obj }) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOAD_REQUEST });
      const reqObj = {
        data: {
          ...obj
        }
      };
      const { data } = await updateAdRequest(id, reqObj);
      if (data?.error?.status === 1) {
        dispatch({ type: LOAD_DONE });
        return { error: 1, message: data?.error?.message };
      }

      toast.success('Updated Sucessfully!', {
        position: 'top-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });

      dispatch(getAdAction());
      return 0;
    } catch (err) {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: err.message };
    }
  };

export const toggleAdStatusAction = (id, status) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await toggleStatusRequest(id, status);
    if (data?.error?.code === 1) {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }

    toast.success('Ad Status Changed Sucessfully!', {
      position: 'top-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });

    dispatch(getAdAction());
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};

export const deleteAdAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await deleteAdRequest(id);
    if (data?.error?.code === 1) {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }

    toast.success('Ad Deleted Sucessfully!', {
      position: 'top-left',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });

    dispatch(getAdAction());
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};

export const getRoutesAction = (values) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await getRoutesRequest(values);
    checkIfResponseOk(data);

    dispatch({ type: GET_ROUTES, payload: data.content.data.all });
    return data.content.data.all;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};

export const unassignRouteAction = (id, routeID) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await unassignRouteRequest(id, routeID);
    if (data?.error?.code === 1) {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }

    toast.success('Route Unassigned Sucessfully!', {
      position: 'top-left',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });

    dispatch(getAdAction());
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};

export const assignRoutesAction = (obj) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });
    const reqObj = {
      data: {
        ...obj
      }
    };
    const { data } = await assignRouteRequest(reqObj);
    if (data?.error?.status === 1) {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }

    toast.success('Routes assigned Sucessfully!', {
      position: 'top-left',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });

    dispatch(getAdAction());
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};

// price plan
export const getPricePlanAction = () => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await getPricePlanRequest();
    if (data?.error?.status >= 400) {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }

    dispatch({ type: GET_PRICE_PLAN, payload: data.content.data.all });
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};

export const assignAdToPlacesAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await assignAdToPlacesRequest(payload);
    if (data?.error?.status >= 400) {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }
    // checkIfResponseOk(data);
    toast.success('Assigned Sucessfully!');
    dispatch(getAdAction());
    return 0;
  } catch (err) {
    return { error: 1, message: err.message };
  }
};
