import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@material-ui/lab';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { FormControl, InputLabel, Select, MenuItem, Stack, Grid } from '@material-ui/core';
// import { getVendorsAction } from 'src/store/actions/VendorsActions';
import { getAdAction } from 'src/store/actions/AdActions';
import { sortArrayBy } from 'src/utils/utilities';
// import { getWalletAction } from 'src/store/actions/WalletActions';
import { getVendorAction } from 'src/store/actions/VendorActions';
import { getConstantsAction } from 'src/store/actions/EventsActions';

function FilterEvents({ handleFilter, loading }) {
  const dispatch = useDispatch();
  const { eventTypes, isLoading } = useSelector((state) => state.events);

  useEffect(() => {
    dispatch(getConstantsAction());
  }, []);

  const { handleSubmit, handleChange, values, errors, touched, handleBlur } = useFormik({
    initialValues: {
      type: ''
    },
    validationSchema: Yup.object().shape({
      //   vendorID: Yup.string().required('Vendor is required')
    }),
    onSubmit: (values) => {
      handleFilter({ ...values });
    }
  });

  return (
    <div style={{ width: '100%' }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} alignItems="center">
          <Grid item sm={4}>
            <TextField
              error={Boolean(touched.type && errors.type)}
              helperText={touched.type && errors.type}
              fullWidth
              select
              margin="normal"
              name="type"
              label="Event Type"
              variant="outlined"
              onBlur={handleBlur}
              size="small"
              onChange={handleChange}
              value={values.type}
            >
              <MenuItem value="">Please select</MenuItem>
              {eventTypes?.map((item, index) => (
                <MenuItem key={index} value={item.key}>
                  {' '}
                  {item.name}{' '}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item>
            <LoadingButton type="submit" color="primary" variant="contained" loading={loading}>
              Filter
            </LoadingButton>
          </Grid>
        </Grid>
        {/* </Stack> */}
      </form>
    </div>
  );
}

export default FilterEvents;
