import { getReportsRequest } from '../../api/ReportRequest';
import { GET_REPORTS, LOAD_REQUEST, EMPTY_LIST, LOAD_DONE } from '../types';

export const getReportsAction = (values) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await getReportsRequest(values);
    if (data?.error?.code === '1' && data?.error?.message === 'No more data') {
      dispatch({ type: EMPTY_LIST });
      return { error: 1, message: data?.error?.message };
    } else if (data?.error?.code === '1') {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }

    dispatch({ type: GET_REPORTS, payload: data.content.data });
    return 0;
  } catch (err) {
    return { error: 1, message: err.message };
  }
};
