import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Divider } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@material-ui/lab';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { createTopupAction } from '../../../store/actions/TopupActions';

function NewTopup({ open, handleClose, vendorID }) {
  const dispatch = useDispatch();
  const [redirectUrl, setRedirectUrl] = useState('');
  const userProfile = JSON.parse(sessionStorage.getItem('userProfile'));
  const { isLoading } = useSelector((state) => state.topup);
  const { handleSubmit, handleChange, values, errors, touched, handleBlur } = useFormik({
    initialValues: {
      amount: ''
    },
    validationSchema: Yup.object().shape({
      amount: Yup.string().required('Amount is required')
    }),
    onSubmit: async (values) => {
      const response = await dispatch(createTopupAction({ ...values, vendorID: userProfile.id }));
      if (response?.error === 1) {
        // window.open('https://checkout.paystack.com/z8q3efphpynfnqc', '_blank');
        toast.error(`${response.message}`, {
          position: 'top-left',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          progress: undefined
        });
      } else {
        let a = document.createElement('a');
        a.target = '_blank';
        a.href = `${response.response.authorizationUrl}`;
        a.click();
        // setRedirectUrl(response.authorizationUrl);
        // <a id="anchorID" href={response.authorizationUrl} target="_blank"></a>;
        // window.open(response.authorizationUrl, '_blank');
        // document.getElementById('anchorID').click();
        handleClose();
      }
    }
  });

  return (
    <div>
      <a id="anchorID" href={redirectUrl} target="_blank"></a>;
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Topup Wallet</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <DialogContentText>
              Topup wallet with your card so you can have access to run Ad Campaigns. <br />
              <small>
                <i>Please don't refresh page once topup is created...</i>.
              </small>
              <span style={{ textAlign: 'right', fontSize: '0.8em' }}>Payment by Paystack</span>
            </DialogContentText>
            <TextField
              autoFocus
              margin="normal"
              id="amount"
              label="Amount"
              type="text"
              fullWidth
              value={values.amount}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(touched.amount && errors.amount)}
              helperText={touched.amount && errors.amount}
            />
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            <DialogContentText style={{ textAlign: 'center' }}>
              Or send directly to our acount:
              <br />
              <strong>CHORUS TECHNOLOGY LTD,</strong>
              <br />
              Account Number: <strong>1017696196</strong>
              <br />
              Bank: <strong>Zenith Bank</strong>
              <br />
              <Divider style={{ marginTop: 10, marginBottom: 10 }} />
              <span>
                Send Proof of Payment to admin at <strong>info@chorus-mail.com</strong> or call
                +2348182924818
              </span>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <LoadingButton type="submit" color="primary" variant="contained" loading={isLoading}>
              Create Topup
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default NewTopup;
