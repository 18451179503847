import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Stack from '@material-ui/core/Stack';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { LoadingButton } from '@material-ui/lab';
import { unassignRouteAction } from '../../../store/actions/AdActions';

function ViewRoutes({ open, handleClose, rowData }) {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.Ads);

  const handleUnassignRoute = async (route) => {
    const action = window.confirm(
      `Are you sure you want to Unassign ${route.name} from this Route?`
    );
    if (action) {
      const response = await dispatch(unassignRouteAction(rowData.id, route.id));
      if (response?.error === 1) {
        toast.error(`${response.message}`, {
          position: 'top-left',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          progress: undefined
        });
      } else handleClose();
    }
  };
  return (
    <div>
      <Dialog fullWidth open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Routes</DialogTitle>

        <DialogContent>
          <DialogContentText>List of Routes this Ad is showing at...</DialogContentText>

          {rowData !== null && rowData.routes.length > 0 ? (
            rowData.routes.map((route) => (
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <h5>{route.name}</h5>
                <LoadingButton
                  //   fullWidth
                  loading={isLoading}
                  size="small"
                  type="submit"
                  variant="outlined"
                  style={{ marginBottom: 10 }}
                  onClick={() => handleUnassignRoute(route)}
                >
                  Unassign Route
                </LoadingButton>
              </Stack>
            ))
          ) : (
            <h3 style={{ marginTop: 20 }}>
              {' '}
              This Ad has not been assigned to any route! Note; Ad won't display anywhere until
              assigned to a specific route.
            </h3>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ViewRoutes;
