import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import infoFill from '@iconify/icons-eva/info-fill';
import paperPlaneFill from '@iconify/icons-eva/paper-plane-fill';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@material-ui/core';

// ----------------------------------------------------------------------

export default function UserMoreMenu({
  handleEdit,
  handleDelete,
  handleAssignAd,
  handleViewRoute,
  handleAdEvents,
  handleAssignPlaces
}) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {handleEdit && (
          <MenuItem onClick={handleEdit} sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon icon={editFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
        {handleAdEvents && (
          <MenuItem onClick={handleAdEvents} sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon icon={editFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Ad Conversion" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
        {handleViewRoute && (
          <MenuItem onClick={handleViewRoute} sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon icon={infoFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="View Route" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
        {handleAssignPlaces && (
          <MenuItem onClick={handleAssignPlaces} sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon icon={paperPlaneFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Assign Places" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
        {/* {handleAssignAd && (
          <MenuItem onClick={handleAssignAd} sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon icon={paperPlaneFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Assign Route" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )} */}
        {handleDelete && (
          <MenuItem onClick={handleDelete} sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon icon={trash2Outline} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
