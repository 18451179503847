import React from 'react';
import { Paper } from '@material-ui/core';
import { fCurrency } from 'src/utils/formatNumber';
import { Done } from '@material-ui/icons';

const AdPricePlan = ({ pricing, handlePricePlanSelect, selected }) => {
  const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };
  return (
    <Paper
      onClick={() => handlePricePlanSelect(pricing.id)}
      className="priceplan"
      elevation={2}
      style={{
        padding: 10,
        marginTop: 10,
        cursor: 'pointer',
        position: 'relative',
        border: selected == pricing.id ? '2px solid black' : ''
      }}
    >
      {selected == pricing.id && <Done style={{ position: 'absolute', top: 0, right: 0 }} />}
      <div style={style}>
        <p>Plan Name:</p>
        <h4> &nbsp; {pricing?.name || '-'} </h4>
      </div>
      <div style={style}>
        <p>Ad Click Price:</p>
        <h4> &nbsp; {fCurrency(pricing.onClickPrice)} </h4>
      </div>

      <div style={style}>
        <p>Ad View Price:</p>
        <h4> &nbsp;{fCurrency(pricing.onViewPrice)} </h4>
      </div>

      <div style={style}>
        <p>Ad Conversion Price:</p>
        <h4> &nbsp;{fCurrency(pricing.onConversionPrice)} </h4>
      </div>
    </Paper>
  );
};

export default AdPricePlan;
