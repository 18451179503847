import axios from './index';

export const getAdsRequest = (values) =>
  axios.get(
    `/v1/vendors/get/ads/-/${values?.page || '1'}/${values?.limit || '-'}/${
      values?.countryID || '-'
    }/${values?.stateID || '-'}/${values?.cityID || '-'}/${values?.routeID || '-'}/${
      values?.adType || '-'
    }/${values?.activeStatus || '-'}/${values?.fromDate || '-'}/${values?.toDate || '-'}`
  );

export const createAdsRequest = (reqObj) => axios.post('/v1/vendors/create/ad', reqObj);

export const updateAdRequest = (id, reqObj) => axios.put(`/v1/vendors/update/ad/${id}`, reqObj);

export const toggleStatusRequest = (id, status) =>
  axios.put(`/v1/vendors/toggle/ad/${id}/active-status/${status}`);

export const deleteAdRequest = (id) => axios.delete(`/v1/vendors/delete/ad/${id}`);

export const unassignRouteRequest = (id, routeID) =>
  axios.delete(`/v1/vendors/unassign/ad/${id}/route/${routeID}`);

export const assignRouteRequest = (reqObj) => axios.post(`/v1/vendors/assign/ad/to/routes`, reqObj);

export const assignAdToPlacesRequest = (reqObj) =>
  axios.post(`/v1/vendors/ads/ad/assign/places`, reqObj);

export const unAssignAdToPlacesRequest = (reqObj) =>
  axios.post(`/v1/vendors/ads/ad/unassign/places`, reqObj);

// wallet

export const getRoutesRequest = (values) =>
  axios.get(
    `/v1/vendors/get/routes/-/-/-/${values?.countryID || '-'}/${values?.stateID || '-'}/${
      values?.cityID || '-'
    }`
  );

// ad price plans

export const getPricePlanRequest = () => axios.get('/v1/vendors/price-plans/get/price-plans/-/-');
