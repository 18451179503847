import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Products from './pages/Products';
import Blog from './pages/Blog';
import User from './pages/User';
import NotFound from './pages/Page404';
import AdsModule from './pages/AdsModule';
import TopupModule from './pages/TopupModule';
import TransactionsModule from './pages/TransactionsModule';
import ActivityLogModule from './pages/ActivityLog';
import VendorModule from './pages/VendorModule';
import ReportsModule from './pages/ReportModule';
import EventsModule from './pages/Events';
import ConversionModule from './pages/conversion';
// ----------------------------------------------------------------------

const routes = [
  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      { path: 'dashboard', element: <DashboardApp /> },
      { path: 'app', element: <DashboardApp /> },
      { path: 'dashboard/app', element: <Navigate to="/dashboard" /> },
      { path: 'dashboard/ads', element: <AdsModule /> },
      { path: 'dashboard/topup', element: <TopupModule /> },
      { path: '/dashboard/transactions', element: <TransactionsModule /> },
      { path: '/dashboard/activitylog', element: <ActivityLogModule /> },
      { path: '/dashboard/vendorprofile', element: <VendorModule /> },
      { path: '/dashboard/user', element: <User /> },
      { path: '/dashboard/products', element: <Products /> },
      { path: '/dashboard/blog', element: <Blog /> },
      { path: '/dashboard/reports', element: <ReportsModule /> },
      { path: '/dashboard/conversion-events', element: <EventsModule /> },
      { path: '/dashboard/conversions', element: <ConversionModule /> },
      { path: '/', element: <Navigate to="/dashboard/app" replace /> }
    ]
  },
  {
    path: '/',
    element: <LogoOnlyLayout />,
    children: [
      { path: '/', element: <Login /> },
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      // { path: '404', element: <NotFound /> },
      { path: '*', element: <Navigate to="/login" /> }
    ]
  }

  // { path: '*', element: <Navigate to="/404" replace /> }
];

export default function Router() {
  const token = sessionStorage.getItem('token');
  const AUTHENTICATED_ROUTES = useRoutes([routes[0]]);
  const UNAUTHENTICATED_ROUTES = useRoutes([routes[1]]);
  const element = token ? AUTHENTICATED_ROUTES : UNAUTHENTICATED_ROUTES;
  return element;
}
