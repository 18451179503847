import React, { useEffect, useState } from 'react';
// material
import { Box, Grid, Container, Typography, Stack } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../components/Page';
import CustomCard from './components/CustomCard';
import { getVendorAction } from '../../store/actions/VendorActions';
import UpdatePassword from './components/UpdatePassword';
import { AppNewUsers, WalletInfo } from 'src/components/_dashboard/app';
import { CountDisplay } from 'src/components/_dashboard/app/AppNewUsers';

function VendorModule() {
  const dispatch = useDispatch();
  const { isLoading, vendor } = useSelector((state) => state.vendors);

  const userProfile = JSON.parse(sessionStorage.getItem('userProfile'));
  const [isUpdatePwdModalOpen, setIsUpdatePwdModalOpen] = useState();

  useEffect(() => {
    dispatch(getVendorAction());
  }, []);

  const handleUpdatePasswordModal = () => {
    setIsUpdatePwdModalOpen(!isUpdatePwdModalOpen);
  };

  const handleCloseUpdatePwdModal = () => {
    setIsUpdatePwdModalOpen(false);
  };
  return (
    <Page title="Vendor Profile">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Hello {userProfile?.fullname} , </Typography>
        </Box>
        <Grid container spacing={3}>
          <CustomCard handleUpdateAdminPwd={handleUpdatePasswordModal} />
        </Grid>
        <Grid container spacing={2} marginTop={2}>
          <Grid item item xs={12} sm={6} md={3}>
            <CountDisplay value={userProfile?.referralCode} title="Referral Code" />
          </Grid>

          <Grid item item xs={12} sm={6} md={3}>
            {<CountDisplay value={userProfile?.childrenReferralsCount} title="Total Referrals" />}{' '}
          </Grid>
          {/* {userProfile.referredBy && (
            <Grid item item xs={12} sm={6} md={3}>
              <CountDisplay value={userProfile?.referredBy} title="Referred By" />
            </Grid>
          )} */}
        </Grid>


        <Box sx={{ pb: 5, pt: 5 }}>
          <Typography variant="h4">Complaints, </Typography>
          <Typography variant="body1">
            Send a mail to <strong>info@chorus-mail.com</strong>{' '}
          </Typography>
        </Box>
      </Container>
      <UpdatePassword open={isUpdatePwdModalOpen} handleClose={handleCloseUpdatePwdModal} />
    </Page>
  );
}

export default VendorModule;
