import { format, formatDistanceToNow } from 'date-fns';
import moment from 'moment';
// ----------------------------------------------------------------------

export function fDate(date) {
  // return format(new Date(date), 'dd MMMM yyyy');
  return moment.unix(date).format('dddd, MMMM Do YYYY, h:mm:ss a');
}

export function fDateTime(date) {
  // return format(new Date(date), 'dd MMM yyyy hh:mm');
  return moment.unix(date).format('dddd, MMMM Do YYYY, h:mm:ss a');
}

export function fDateTimeSuffix(date) {
  // return format(new Date(date), 'dd/MM/yyyy hh:mm p');
  return moment.unix(date).format('dddd, MMMM Do YYYY, h:mm:ss a');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

export const formatUTCDate = (date) => moment(new Date(date)).utc().format('hh:mm a, Do MMMM YYYY');
