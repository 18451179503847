import React, { useEffect, useState } from 'react';
import { filter } from 'lodash';
import {
  Card,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  LinearProgress,
  Chip,
  Box,
  Tooltip
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import refresh from '@iconify/icons-eva/refresh-fill';
import { useDispatch, useSelector } from 'react-redux';
import { Label } from '@material-ui/icons';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/_dashboard/user';
import { getTopupAction } from '../../store/actions/TopupActions';
import { fDate, fDateTime, fDateTimeSuffix, formatUTCDate } from '../../utils/formatTime';
import { fCurrency } from '../../utils/formatNumber';
import { toast } from 'react-toastify';
// import FilterTopup from './components/FilterTopup';
import { deleteAdEventsAction, getAdEventsAction } from 'src/store/actions/EventsActions';
import CreateAdEvent from './components/CreateEvent';
import EditEvent from './components/EditEvent';
import ViewAdInfo from './components/ViewAdInfo';
import FilterEvents from './components/FilterEvents';
import Table from '../../components/table';

function EventsModule() {
  const dispatch = useDispatch();
  const { isLoading, events } = useSelector((state) => state.events);

  const [filterLoader, setFilterLoader] = useState(false);
  const [openNewTopup, setOpenNewTopup] = useState(false);
  const [openEditEvent, setOpenEditEvent] = useState(false);
  const [openAdInfo, setOpenAdInfo] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const toggleModal = () => {
    setOpenNewTopup(!openNewTopup);
  };

  useEffect(() => {
    dispatch(getAdEventsAction());
  }, []);

  const toggleEditModal = () => {
    setOpenEditEvent(false);
  };

  const handleEditEvent = (row) => {
    setCurrentItem(row);
    setOpenEditEvent(true);
  };

  const handleViewAdInfo = (row) => {
    setCurrentItem(row);
    setOpenAdInfo(true);
  };

  const handleCloseAdInfo = () => {
    setOpenAdInfo(false);
  };

  const handleDeleteEvent = (id) => {
    const response = window.confirm('Are you sure you want to delete this Event?');
    if (response) {
      const res = dispatch(deleteAdEventsAction(id));
      if (res?.error === 1) {
        toast.error(`${response.message}`);
      }
    }
  };

  const _filterEvents = async (values) => {
    setFilterLoader(true);
    const response = await dispatch(getAdEventsAction(values));
    if (response?.error === 1) {
      toast.info(`${response.message}`);
    }
    setFilterLoader(false);
  };

  const TABLE_HEAD = [
    { id: 'id', label: 'S/N', render: (index, row) => index + 1 },
    {
      id: 'name',
      label: 'Name',
      align: 'left'
    },
    {
      id: 'type',
      label: 'Event Type',
      align: 'left'
    },
    {
      id: 'callbackUrl',
      label: 'Callback Url',
      align: 'left'
    },
    {
      id: 'webhook',
      label: 'Webhook',
      align: 'left'
    },
    {
      id: 'dateCreated',
      label: 'Date Created',
      align: 'left',
      render: (index, rowData) => fDate(rowData.dateCreated)
    },
    {
      id: 'action',
      label: 'Add Info',
      align: 'left',
      render: (index, rowData) => (
        <>
          {rowData?.ad ? (
            <Button onClick={() => handleViewAdInfo(rowData)}>View Ad</Button>
          ) : (
            'All Ads'
          )}
        </>
      )
    },
    {
      id: 'action',
      label: 'Actions',
      render: (index, rowData) => (
        <UserMoreMenu
          handleEdit={() => handleEditEvent(rowData)}
          handleDelete={() => handleDeleteEvent(rowData.id)}
          handleAdInfo={() => handleViewAdInfo(rowData)}
        />
      )
    }
  ];

  return (
    <Page title="Events Module ">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Conversion Events
          </Typography>
          <Button variant="contained" onClick={toggleModal} startIcon={<Icon icon={plusFill} />}>
            New Event
          </Button>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <FilterEvents handleFilter={_filterEvents} loading={filterLoader} />
        </Stack>

        <Table
          tableHeader={TABLE_HEAD}
          data={events}
          isLoading={isLoading}
          filterField="fullname"
          tableTitle="Conversion Events"
        />

        {/* Modals */}
        <CreateAdEvent open={openNewTopup} handleClose={toggleModal} />
        <EditEvent open={openEditEvent} handleClose={toggleEditModal} rowData={currentItem} />
        <ViewAdInfo open={openAdInfo} handleClose={handleCloseAdInfo} rowData={currentItem} />
      </Container>
    </Page>
  );
}

export default EventsModule;
