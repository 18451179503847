import { EMPTY_LIST, GET_TOPUP, LOAD_DONE, LOAD_REQUEST } from '../types';

const initialState = {
  TOPUPLIST: [],
  isLoading: false
};

export const TopupReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_REQUEST: {
      return { ...state, isLoading: true };
    }
    case GET_TOPUP: {
      return { ...state, TOPUPLIST: payload, isLoading: false };
    }
    case EMPTY_LIST: {
      return { ...state, TOPUPLIST: [], isLoading: false };
    }
    case LOAD_DONE: {
      return { ...state, isLoading: false };
    }
    default:
      return state;
  }
};
