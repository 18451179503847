export const LOAD_REQUEST = 'LOAD_REQUEST';
export const LOAD_DONE = 'LOAD_DONE';
export const GET_DASHBOARD = 'GET_DASHBOARD';
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS';
export const EMPTY_LIST = 'EMPTY_LIST';

// Ad
export const GET_ADS = 'GET_ADS';

// ad price plan
export const GET_PRICE_PLAN = 'GET_PRICE_PLAN';

// transactions
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';

// topup
export const GET_TOPUP = 'GET_TOPUP';

// logs
export const GET_LOGS = 'GET_LOGS';

// routes
export const GET_ROUTES = 'GET_ROUTES';

// wallet
export const GET_WALLET = 'GET_WALLET';

//
export const GET_VENDOR = 'GET_VENDOR';

// reports
export const GET_REPORTS = 'GET_REPORTS';

// events
// export const GET_EVENTS = 'GET_EVENTS';

// constants
export const GET_CONSTANTS = 'GET_CONSTANTS';

// events
export const GET_AD_EVENTS = 'GET_AD_EVENTS';

// conversion
export const GET_CONVERSION = 'GET_CONVERSION';

// location
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_STATES = 'GET_STATES';
export const GET_CITIES = 'GET_CITIES';

// routes
