import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Autocomplete, Grid } from '@material-ui/core';
import { useFormik, Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@material-ui/lab';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { assignAdToPlacesAction, getRoutesAction } from 'src/store/actions/AdActions';
import {
  getCitiesAction,
  getCountriesAction,
  getStatesAction
} from 'src/store/actions/WorldActions';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

function AssignPlaces({ open, handleClose, rowData }) {
  const dispatch = useDispatch();
  const [options, setOptions] = useState({ country: [], state: [], city: [] });
  const { countries } = useSelector((state) => state.world);
  const [loading, setLoading] = useState(false);
  //   const [debounceTimer, setDebounceTimer] = useState('');

  useEffect(() => {
    dispatch(getCountriesAction());
  }, []);

  useEffect(() => {
    if (countries.length > 0) {
      const countryOptions = countries.map((item) => ({ label: item.name, id: item.id }));
      countryOptions.sort((a, b) => a.label.localeCompare(b.label));

      setOptions((prev) => ({
        country: [...countryOptions]
      }));
    }
  }, [countries]);

  const processDropdownOptions = (array = []) => {
    return array.length > 0
      ? array
          .map((item) => ({ label: item.name, id: item.id }))
          .sort((a, b) => a.label.localeCompare(b.label))
      : [];
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Assign Device to Location</DialogTitle>
        <Formik
          initialValues={{
            data: [
              {
                countryID: null,
                countryLabel: '',
                stateID: null,
                stateList: [],
                stateLabel: '',
                cityID: null,
                routeLabel: '',
                routeLabel: '',
                routeID: null,
                routeList: []
              }
            ]
          }}
          validationSchema={Yup.object().shape({
            data: Yup.array().of(
              Yup.object().shape({
                countryID: Yup.string().nullable(),
                stateID: Yup.string().nullable(),
                routeID: Yup.string().nullable()
              })
            )
          })}
          onSubmit={async (values) => {
            setLoading(true);
            const data = {
              adID: rowData.id,
              placeIDs: values.data
            };
            const response = await dispatch(assignAdToPlacesAction(data));
            setLoading(false);
            if (response?.error === 1) {
              toast.error(`${response.message}`);
              return;
            }
            handleClose();
          }}
          render={({
            values,
            handleChange,
            setFieldValue,
            handleBlur,
            setValues,
            handleSubmit
          }) => (
            <form onSubmit={handleSubmit}>
              <FieldArray
                name="data"
                render={(arrayHelper) => (
                  <DialogContent>
                    <DialogContentText>
                      Assign route to either country or state or city...
                    </DialogContentText>
                    {values.data.map((location, index) => (
                      <Grid container spacing={2} key={index}>
                        <Grid item xs={4}>
                          <Autocomplete
                            disablePortal
                            id="countryID"
                            options={options.country}
                            name="countryID"
                            onBlur={handleBlur}
                            onChange={async (event, newValue) => {
                              setFieldValue(`data.${index}.countryID`, newValue?.id || null);
                              setFieldValue(`data.${index}.countryLabel`, newValue?.label);
                              if (newValue?.id !== undefined) {
                                const resultingState = await dispatch(
                                  getStatesAction({ countryID: newValue.id })
                                );
                                setFieldValue(
                                  `data.${index}.stateList`,
                                  processDropdownOptions(resultingState)
                                );
                              }
                            }}
                            fullWidth
                            value={values.countryLabel}
                            renderInput={(params) => (
                              <TextField {...params} margin="normal" label="Country" />
                            )}
                            autoComplete={false}
                          />
                        </Grid>
                        <Grid item xs={4} gap={5}>
                          <Autocomplete
                            disablePortal
                            id="stateID"
                            options={values.data[index].stateList}
                            name="stateID"
                            onBlur={handleBlur}
                            onChange={async (event, newValue) => {
                              setFieldValue(`data.${index}.stateID`, newValue?.id || null);
                              setFieldValue(`data.${index}.stateLabel`, newValue?.label);
                              if (newValue?.id !== undefined) {
                                const resultingRoutes = await dispatch(
                                  getRoutesAction({ stateID: newValue.id })
                                );
                                setFieldValue(
                                  `data.${index}.routeList`,
                                  processDropdownOptions(resultingRoutes)
                                );
                              }
                            }}
                            fullWidth
                            value={values.stateLabel}
                            renderInput={(params) => (
                              <TextField {...params} margin="normal" label="State" />
                            )}
                            autoComplete={false}
                          />
                        </Grid>
                        <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                          <Autocomplete
                            disablePortal
                            id="routeID"
                            options={values.data[index].routeList}
                            name="routeID"
                            onBlur={handleBlur}
                            onChange={(event, newValue) => {
                              setFieldValue(`data.${index}.routeID`, newValue?.id || null);
                              setFieldValue(`data.${index}.routeLabel`, newValue?.label);
                            }}
                            fullWidth
                            value={values.routeLabel}
                            renderInput={(params) => (
                              <TextField {...params} margin="normal" label="Routes" />
                            )}
                            autoComplete={false}
                          />

                          {index !== 0 && (
                            <div
                              onClick={() => arrayHelper.remove(index)}
                              style={{ cursor: 'pointer' }}
                            >
                              <RemoveCircleOutlineIcon />
                            </div>
                          )}
                        </Grid>
                      </Grid>
                    ))}
                    <div
                      onClick={() =>
                        arrayHelper.push({
                          countryID: null,
                          countryLabel: '',
                          stateID: null,
                          stateList: [],
                          stateLabel: '',
                          cityID: null,
                          routeLabel: '',
                          routeLabel: '',
                          routeID: null,
                          routeList: []
                        })
                      }
                    >
                      <AddCircleOutlineIcon />
                    </div>
                  </DialogContent>
                )}
              />

              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <LoadingButton type="submit" color="primary" variant="contained" loading={loading}>
                  Assign Places
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </div>
  );
}

export default AssignPlaces;
