import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { FormControl, InputLabel, Select, MenuItem, Stack, Grid } from '@material-ui/core';
import { sortArrayBy } from 'src/utils/utilities';

function FilterReports({ handleFilter, loading, page }) {
  const dispatch = useDispatch();
  const [fromDate, setFromDate] = useState(moment().subtract(1, 'd').format('YYYY-MM-DD')); //gets the last sunday as default fromDate value
  const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));

  const { handleSubmit, handleChange, values, errors, touched, handleBlur } = useFormik({
    initialValues: {
      from: '',
      to: ''
    },
    onSubmit: (values) => {
      const fdate = moment(fromDate).unix();
      const tdate = moment(toDate).unix();
      handleFilter({ to: tdate, from: fdate });
    }
  });

  return (
    <div style={{ width: '100%', marginBottom: 20 }}>
      <form onSubmit={handleSubmit}>
        {/* <Stack direction="row" alignItems="center" spacing={2} flexWrap="wrap" justifyContent="space-between"> */}
        <Grid container spacing={2} style={{ alignItems: 'center' }}>
          <Grid item sm={3}>
            <TextField
              autoFocus
              margin="normal"
              id="fromDate"
              label="From Date"
              type="date"
              fullWidth
              defaultValue={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              //   onBlur={handleBlur}
              //   error={Boolean(touched.from && errors.from)}
              //   helperText={touched.from && errors.from}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>

          <Grid item sm={3}>
            <TextField
              autoFocus
              margin="normal"
              id="toDate"
              label="To Date"
              type="date"
              fullWidth
              defaultValue={toDate}
              onChange={(e) => setToDate(e.target.value)}
              //   onBlur={handleBlur}
              //   error={Boolean(touched.to && errors.to)}
              //   helperText={touched.to && errors.to}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>

          <Grid item sm={3}>
            <LoadingButton type="submit" color="primary" variant="contained" loading={loading}>
              Filter
            </LoadingButton>
          </Grid>
        </Grid>
        {/* </Stack> */}
      </form>
    </div>
  );
}

export default FilterReports;
