import { getCitiesRequest, getCountriesRequest, getStateRequest } from 'src/api/WorldRequest';
import { GET_CITIES, GET_COUNTRIES, GET_STATES, LOAD_DONE, LOAD_REQUEST } from '../types';

export const getCountriesAction = (values) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await getCountriesRequest(values);
    if (data?.error?.status >= 400 || data?.content?.data == null) {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }

    dispatch({ type: GET_COUNTRIES, payload: data.content.data.all });
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};

export const getStatesAction = (values) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await getStateRequest(values);
    if (data?.error?.status >= 400 || data?.content?.data == null) {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }

    dispatch({ type: GET_STATES, payload: data.content.data.all });
    return data.content.data.all;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};

export const getCitiesAction = (values) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_REQUEST });

    const { data } = await getCitiesRequest(values);
    if (data?.error?.status >= 400 || data?.content?.data == null) {
      dispatch({ type: LOAD_DONE });
      return { error: 1, message: data?.error?.message };
    }

    dispatch({ type: GET_CITIES, payload: data.content.data.all });
    return 0;
  } catch (err) {
    dispatch({ type: LOAD_DONE });
    return { error: 1, message: err.message };
  }
};
