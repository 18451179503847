import React, { useEffect, useState } from 'react';
import { filter } from 'lodash';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  LinearProgress,
  Chip
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { useDispatch, useSelector } from 'react-redux';
import { Label } from '@material-ui/icons';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/_dashboard/user';
// import TOPUPLIST from '../../_mocks_/topup.json';
import { getTransactonAction } from '../../store/actions/TransactionActions';
import { fDateTime } from '../../utils/formatTime';
import { fCurrency } from '../../utils/formatNumber';
import FilterTransactions from './components/FilterTransactions';

const TABLE_HEAD = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'fullname', label: 'Vendor Fullname', alignRight: false },
  // { id: 'views', label: 'AD Views', alignRight: false },
  { id: 'cost', label: 'AD Cost' },
  // { id: 'status', label: 'AD Status', alignRight: false },
  { id: 'Date', label: 'Date', alignRight: false },
  { id: '', label: 'Summary', alignRight: false }
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.fullname.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

function TransactionsModule() {
  const dispatch = useDispatch();
  const { isLoading, TRANSACTIONS_LIST } = useSelector((state) => state.transactions);

  const [filterLoader, setFilterLoader] = useState(false);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('fullname');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - TRANSACTIONS_LIST.length) : 0;

  const filteredUsers = applySortFilter(
    TRANSACTIONS_LIST,
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  useEffect(() => {
    dispatch(getTransactonAction());
  }, []);

  const _filterTransactions = async (values) => {
    setFilterLoader(true);
    const response = await dispatch(getTransactonAction(values));
    if (response?.error === 1) {
      toast.info(`${response.message}`, {
        position: 'top-left',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        progress: undefined
      });
    }
    setFilterLoader(false);
  };
  return (
    <Page title="Transactions Module ">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Ad Summary
          </Typography>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <FilterTransactions handleFilter={_filterTransactions} loading={filterLoader} />
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {isLoading ? <LinearProgress /> : null}
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={TRANSACTIONS_LIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  //   onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const {
                        id,
                        vendor: { fullname },
                        views,
                        cost,
                        ad: { activeStatus },

                        ad: { actionLink },
                        dateCreated
                      } = row;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          //   selected={isItemSelected}
                          //   aria-checked={isItemSelected}
                        >
                          <TableCell>
                            <Label />
                          </TableCell>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{fullname}</TableCell>
                          {/* <TableCell align="center"> {views} </TableCell> */}

                          <TableCell align="center"> {fCurrency(cost)} </TableCell>

                          {/* <TableCell align="center"> {activeStatus} </TableCell> */}

                          <TableCell align="left"> {fDateTime(dateCreated)} </TableCell>

                          <TableCell align="left">
                            <Stack direction="column" alignItems="flex-start">
                              <h4>
                                {`${row.ad.views} commuters viewed AD ${row.ad.id} with Action Link "${actionLink}" at ${row?.route?.name}`}
                              </h4>
                              <div>
                                AD Status: &nbsp;
                                <Chip
                                  label={activeStatus === 1 ? 'Active' : 'Inactive'}
                                  color={activeStatus === 1 ? 'primary' : 'default'}
                                />
                              </div>
                            </Stack>
                          </TableCell>
                          {/* <TableCell align="right">
                            <UserMoreMenu />
                          </TableCell> */}
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={TRANSACTIONS_LIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}

export default TransactionsModule;
