import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@material-ui/lab';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { FormControl, InputLabel, Select, MenuItem, Stack, Grid } from '@material-ui/core';
// import { getVendorsAction } from 'src/store/actions/VendorsActions';
import { getAdAction } from 'src/store/actions/AdActions';
import { sortArrayBy } from 'src/utils/utilities';
// import { getWalletAction } from 'src/store/actions/WalletActions';
import { getVendorAction } from 'src/store/actions/VendorActions';

function FilterTopup({ handleFilter, loading }) {
  const dispatch = useDispatch();
  const { VENDORS_LIST } = useSelector((state) => state.vendors);
  //   const { WALLET_LIST } = useSelector((state) => state.wallet);

  const [isLoading, setIsLoading] = useState(false);
  const [fromDate, setFromDate] = useState(moment().subtract(1, 'd').format('YYYY-MM-DD')); //gets the last sunday as default fromDate value
  const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));

  useEffect(() => {
    dispatch(getVendorAction());
    // dispatch(getWalletAction());
  }, []);

  const { handleSubmit, handleChange, values, errors, touched, handleBlur } = useFormik({
    initialValues: {
      vendorID: '',
      walletID: '',
      reference: '',
      status: '',
      from: '',
      to: ''
    },
    validationSchema: Yup.object().shape({
      //   vendorID: Yup.string().required('Vendor is required')
    }),
    onSubmit: (values) => {
      const fdate = moment(fromDate).unix();
      const tdate = moment(toDate).unix();
      handleFilter({ ...values, to: tdate, from: fdate });
    }
  });

  return (
    <div style={{ width: '100%' }}>
      <form onSubmit={handleSubmit}>
        {/* <Stack direction="row" alignItems="center" spacing={2} flexWrap="wrap" justifyContent="space-between"> */}
        <Grid container spacing={2}>
          {/* <Grid item sm={4}>
            <TextField
              error={Boolean(touched.walletID && errors.walletID)}
              helperText={touched.walletID && errors.walletID}
              fullWidth
              select
              margin="normal"
              name="walletID"
              label="Wallet"
              variant="outlined"
              onBlur={handleBlur}
              size="small"
              onChange={handleChange}
              value={values.walletID}
            >
              <MenuItem value="">Please select Wallet</MenuItem>
              {Boolean(WALLET_LIST) &&
                sortArrayBy(WALLET_LIST).map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.vendor.fullname}
                  </MenuItem>
                ))}
            </TextField>
          </Grid> */}

          <Grid item sm={3}>
            <TextField
              error={Boolean(touched.status && errors.status)}
              helperText={touched.status && errors.status}
              fullWidth
              select
              margin="normal"
              name="status"
              label="Status"
              variant="outlined"
              onBlur={handleBlur}
              size="small"
              onChange={handleChange}
              value={values.status}
            >
              <MenuItem value="">Please select Status</MenuItem>
              <MenuItem value="1">Successful</MenuItem>
              <MenuItem value="0">Pending</MenuItem>
            </TextField>
          </Grid>

          <Grid item sm={3}>
            <TextField
              autoFocus
              margin="normal"
              id="reference"
              label="Reference"
              type="text"
              fullWidth
              value={values.reference}
              onChange={handleChange}
              onBlur={handleBlur}
              size="small"
              error={Boolean(touched.reference && errors.reference)}
              helperText={touched.reference && errors.reference}
            />
          </Grid>

          <Grid item sm={3}>
            <TextField
              autoFocus
              margin="normal"
              id="fromDate"
              label="From Date"
              type="date"
              fullWidth
              defaultValue={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              onBlur={handleBlur}
              error={Boolean(touched.from && errors.from)}
              helperText={touched.from && errors.from}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>

          <Grid item sm={3}>
            <TextField
              autoFocus
              margin="normal"
              id="toDate"
              label="To Date"
              type="date"
              fullWidth
              defaultValue={toDate}
              //   value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              onBlur={handleBlur}
              error={Boolean(touched.to && errors.to)}
              helperText={touched.to && errors.to}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>

          <Grid item>
            <LoadingButton type="submit" color="primary" variant="contained" loading={loading}>
              Filter
            </LoadingButton>
          </Grid>
        </Grid>
        {/* </Stack> */}
      </form>
    </div>
  );
}

export default FilterTopup;
