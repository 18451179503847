import axios from 'axios';
import { toast } from 'react-toastify';
import { logoutAction } from '../store/actions/AuthActions';

// export const baseURL = 'https://apis.touchandpay.me/lamata-bus-wifi';

export const baseURL =
  process.env.NODE_ENV === 'development'
    ? 'https://apis.touchandpay.me/lamata-bus-wifi'
    : 'https://apis.cowrywifi.com/cowry-wifi';

const axiosInstance = axios.create({
  baseURL
});

axiosInstance.interceptors.request.use((config) => {
  const token = sessionStorage.getItem('token');

  if (token) {
    config.headers = {
      Token: `bearer ${token}`,
      'Content-Type': 'application/json'
    };
  }
  return config;
});

axiosInstance.interceptors.response.use((response) => {
  // to trigger automatic logout if the auth is invalid
  if (response.data?.error?.message === 'Expired session. Please login.') {
    // logoutRequest();
    setTimeout(() => {
      toast.info("Expired session! You'll be logged out!");
    }, [5000]);

    logoutAction();
    //  window.location.pathname = '/';
  }
  return response;
});

export default axiosInstance;
