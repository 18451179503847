import { LOAD_DONE, LOAD_REQUEST, GET_AD_EVENTS, GET_CONSTANTS } from '../types';

const initialState = {
  events: [],
  eventTypes: [],
  isLoading: false
};

export const EventsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_REQUEST: {
      return { ...state, isLoading: true };
    }
    case GET_AD_EVENTS: {
      return { ...state, events: payload, isLoading: false };
    }
    case LOAD_DONE: {
      return { ...state, isLoading: false };
    }
    case GET_CONSTANTS: {
      return { ...state, eventTypes: payload, isLoading: false };
    }
    default:
      return state;
  }
};
