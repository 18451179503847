import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { MenuItem } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { LoadingButton } from '@material-ui/lab';
import { updateAdsAction } from '../../../store/actions/AdActions';
import { createAdEventsAction, getConstantsAction } from 'src/store/actions/EventsActions';

function AdEvents({ open, handleClose, rowData }) {
  const dispatch = useDispatch();
  const { eventTypes, isLoading } = useSelector((state) => state.events);
  const [currentItem, setCurrentItem] = useState(null);

  useEffect(() => {
    dispatch(getConstantsAction());
  }, []);

  const { handleChange, handleSubmit, errors, touched, handleBlur, values, setFieldValue } =
    useFormik({
      initialValues: {
        name: '',
        type: '',
        callbackUrl: '',
        webhook: '',
        adID: ''
      },
      validationSchema: Yup.object().shape({
        name: Yup.string().required('Required'),
        type: Yup.string().required('Required'),
        callbackUrl: Yup.string(),
        webhook: Yup.string()
      }),
      onSubmit: async (values) => {
        const response = await dispatch(createAdEventsAction(values));
        if (response?.error === 1) {
          toast.error(`${response.message}`);
        } else handleClose();
      }
    });

  useEffect(() => {
    if (rowData) {
      setFieldValue('adID', rowData?.id);
    }
  }, [rowData]);

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Ad Conversion Events</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Grid container>
              <TextField
                autoFocus
                margin="normal"
                id="name"
                name="name"
                label="Event Name"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                error={Boolean(errors.name && touched.name)}
                fullWidth
              />

              <Grid item sm={12}>
                <TextField
                  error={Boolean(touched.type && errors.type)}
                  helperText={touched.type && errors.type}
                  fullWidth
                  select
                  margin="normal"
                  name="type"
                  label="Event Type"
                  variant="outlined"
                  onBlur={handleBlur}
                  size="small"
                  onChange={handleChange}
                  value={values.type}
                >
                  <MenuItem value="">Please select</MenuItem>
                  {eventTypes?.map((item, index) => (
                    <MenuItem key={index} value={item.key}>
                      {' '}
                      {item.name}{' '}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <TextField
                id="callbackUrl"
                label="Callback Url"
                variant="outlined"
                fullWidth
                margin="normal"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.callbackUrl}
                error={Boolean(errors.callbackUrl && touched.callbackUrl)}
                helperText={touched.callbackUrl && errors.callbackUrl}
              />

              <TextField
                id="webhook"
                label="Webhook"
                variant="outlined"
                fullWidth
                margin="normal"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.webhook}
                error={Boolean(errors.webhook && touched.webhook)}
                helperText={touched.webhook && errors.webhook}
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <LoadingButton loading={isLoading} type="submit" color="primary" variant="contained">
              Add Event
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default AdEvents;
