import { GET_LOGS, LOAD_DONE, LOAD_REQUEST } from '../types';

const initialState = {
  LOGLIST: [],
  isLoading: false
};

export const LogsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOAD_REQUEST: {
      return { ...state, isLoading: true };
    }
    case GET_LOGS: {
      return { ...state, LOGLIST: payload, isLoading: false };
    }
    case LOAD_DONE: {
      return { ...state, isLoading: false };
    }
    default:
      return state;
  }
};
